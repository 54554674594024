<template>
  <div
    :class="wrapperClasses"
    class="flex-col hidden lg:flex absolute font-normal left-1/2 transform -translate-x-1/2 p-4 w-96 justify-center rounded-lg"
  >
    {{ text }}
    <span
      class="w-5 h-5 absolute transform rotate-45 left-1/2 -ml-2"
      :class="arrowClasses"
    />
  </div>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        required: true,
        default: ''
      },
      type: {
        default: 'primary',
        validator: val => ['primary', 'secondary'].includes(val)
      }
    },
    computed: {
      wrapperClasses () {
        if (this.type === 'secondary') {
          return 'border-2 border-gray-200 bg-white -mt-32'
        }
        return 'bg-blue-500 text-white -mt-36'
      },
      arrowClasses () {
        if (this.type === 'secondary') {
          return 'bg-white border-b-2 border-r-2 -bottom-3'
        }
        return 'bg-blue-500 -bottom-2'
      }
    }
  }
</script>
