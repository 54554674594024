<script>
  export default {
    name: 'FormWrapper',
    provide () {
      return {
        formValidation: this.validation,
        // https://github.com/vuejs/vue/issues/7017#issuecomment-480906691
        formServerErrors: () => this.serverErrors
      }
    },
    props: {
      validation: {
        type: Object,
        required: true
      },
      serverErrors: {
        type: Object,
        default: () => {}
      }
    },
    render (h) {
      return h('div', this.$slots.default)
    }
  }

</script>
