import { configure, extend } from 'vee-validate'
import { email, max, min, required } from 'vee-validate/dist/rules'

configure({ mode: 'passive' })

// No message specified.
extend('email', {
  ...email,
  message: 'Dit is geen geldige e-mail'
})

// Override the default message.
extend('required', {
    ...required,
    message: 'Dit veld is verplicht'
})

// Dutch postcode validation
extend('postcode', (value) => {
  const postcodeRegex = /^\d{4}\s?[a-zA-Z]{2}$/gm
  if (value && postcodeRegex.test(value.trim())) {
    return true
  }
  return 'Voer een postcode in, bijvoorbeeld: 1234 AA'
})

// House number validation
extend('houseNumber', (value) => {
  const strValue = value.toString()
  if (strValue?.trim().length > 5) return 'Maximaal 5 cijfers'
  if (!strValue?.match(/^\d+$/)) return 'Huisnummer mag alleen cijfers bevatten'
  return true
})

// House number extension validation
extend('houseNumberExtension', (value) => {
  const strValue = value.toString()
  if (strValue?.trim().length > 10) return 'Maximaal 10 karakters'
  if (!strValue?.match(/^[^#]*$/)) return 'De toevoeging mag geen # bevatten'
  return true
})

extend('min', {
  ...min,
  message: 'Voer minimaal {length} cijfers in.'
})

extend('max', {
  ...max,
  message: 'Voer maximaal {length} cijfers in.'
})

extend('maxLen', {
  ...max,
  message: 'Voer maximaal {length} karakters in.'
})
