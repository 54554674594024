export default async function ({ store, params, redirect, error, route }) {
  if (route.name === 'articles-page-id' || route.name === 'category-slug-page-id') {
    if (!params.id || params.id === '1') redirect('/examentips')
  }
  const payload = {
    page: params.id || 1,
    category: params.slug || null
  }
  const articles = await store.dispatch('blog/getArticles', payload)
  if (articles.total === 0) error({ statusCode: 404, message: 'This page could not be found' })
}
