<template>
  <div class="view view-error flex flex-col justify-center py-12">
    <div class="container">
      <div class="flex flex-wrap justify-center px-2 md:px-4">
        <div class="w-full md:w-10/12 px-4">
          <div v-if="error.statusCode && error.statusCode !== 404">
            <h1>
              {{ error.statusCode }}
            </h1>
            <h3>
              {{ error.message }}
            </h3>
          </div>
          <div
            v-else
            class="rich-text-content text-center"
          >
            <h1>Gezakt!</h1>
            <div style="text-align: center;">
              <img
                src="~assets/img/404.gif"
                class="w-auto w-1/2 md:w-1/4 inline"
              >
            </div>
            <h5>
              Nee grapje, je bent alleen gezakt in het vinden van de juiste pagina.
              <br>
              De pagina die je zoekt is niet (meer) beschikbaar.
            </h5>
            <br>
            <p>
              Hier zijn een aantal andere handige pagina’s:<br>
              <br>
              <nuxt-link to="/">Home</nuxt-link><br>
              <nuxt-link to="examentips">Examentips</nuxt-link><br>
              <nuxt-link to="examen-info">Exameninfo</nuxt-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      error: {
        type: Object,
        default: () => {}
      }
    },
    head () {
      return {
        title: this.error.statusCode && this.error.statusCode !== 404 ? this.error.message : 'Pagina niet gevonden'
      }
    }
  }
</script>
