<template>
  <component
    :is="tag"
    :to="to"
    class="button"
    :class="classes"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span
      v-if="type === 'tertiary'"
      class="button-content"
    >
      <slot />
    </span>
    <slot v-else />
    <q-icon
      v-if="type === 'tertiary'"
      name="chevron-right"
      :class="{
        'ml-1': !reversed
      }"
    />
  </component>
</template>

<script>

  export default {
    props: {
      tag: {
        type: String,
        default: 'button'
      },
      to: {
        type: String,
        default: '',
        required: false
      },
      type: {
        type: String,
        default: 'primary',
        validator: val => ['primary', 'secondary', 'tertiary', 'quaternary'].includes(val)
      },
      size: {
        type: String,
        default: '',
        validator: val => ['', 'small'].includes(val)
      },
      rounded: {
        type: Boolean,
        default: false
      },
      center: {
        type: Boolean,
        default: false
      },
      light: {
        type: Boolean,
        default: false
      },
      reversed: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      classes () {
        return [
          `button-${this.type}`,
          this.size && `button-${this.size}`,
          {
            'button-rounded': this.rounded,
            'button-center': this.center,
            'button-light': this.light,
            'button-reversed': this.reversed
          }
        ]
      }
    }
  }

</script>

<style lang="scss">
</style>
