<template>
  <div class="animated-title">
    <slot />
  </div>
</template>

<script>

  import { gsap } from 'gsap'
  import { SplitText } from 'gsap/SplitText'
  import Exclamation from '~/assets/svg/animated-title-symbols/exclamation.svg?inline'
  import Hashtag from '~/assets/svg/animated-title-symbols/hashtag.svg?inline'
  import Arrow from '~/assets/svg/animated-title-symbols/arrow.svg?inline'
  import Bracket from '~/assets/svg/animated-title-symbols/bracket.svg?inline'
  import { mediaQueries } from '@/constants/responsive'

  export default {
    props: {
      speed: {
        type: Number,
        default: 250
      },
      letterDelay: {
        type: Number,
        default: 0
      },
      iterations: {
        type: Number,
        default: 6
      },
      divider: {
        type: Number,
        default: 4
      },
      appear: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        splitTitle: {},
        symbols: [
          Exclamation,
          Hashtag,
          Arrow,
          Bracket
        ],
        colors: [
          '#4692CD', // blue
          '#ABA6D1', // purple
          '#F39200', // orange
          '#E50051', // red
          '#81BC00' // green
        ],
        intervals: [],
        iterationsArr: [],
        isAnimating: false
      }
    },
    watch: {
      appear (v) {
        if (v) this.randomizeLetters()
      }
    },
    mounted () {
      gsap.registerPlugin(SplitText)
      if (this.appear) {
        this.randomizeLetters()
      }
    },
    methods: {
      getRandomFrom (array) {
        const selected = array.selected
        while (selected === (array.selected = Math.floor(Math.random() * array.length))) {};
        return array[array.selected]
      },
      getUniqueRandom (array, max) {
        const random = Math.ceil(gsap.utils.random(0, max))
        if (!array.includes(random)) {
          array.push(random)
        } else {
          this.getUniqueRandom(array, max)
        }
      },
      setup () {
        const title = this.$el.querySelector('.animate')
        this.splitTitle = new SplitText(title)
        this.splitTitleCharsCopy = [...this.splitTitle.chars].map(el => el.innerHTML)
      },
      randomizeLetters () {
        if (this.isAnimating) return false
        this.setup()
        if (!this.splitTitle.isSplit) return false
        this.isAnimating = true
        const numOfLettersToAnim = Math.round(this.splitTitle.chars.length / this.divider)
        let letterIndexes = []
        for (let i = 0; i < numOfLettersToAnim; i++) {
          this.getUniqueRandom(letterIndexes, this.splitTitle.chars.length - 1)
        }
        letterIndexes = letterIndexes.sort((a, b) => a - b)
        const chars = this.splitTitle.chars
        letterIndexes.forEach((letterIndex, i) => {
          this.iterationsArr.push(0)
          setTimeout(() => {
            this.intervals[i] = setInterval(() => {
              this.iterationsArr[i]++
              if (this.iterationsArr[i] >= this.iterations) {
                clearInterval(this.intervals[i])
                if (i >= this.intervals.length - 1) {
                  this.splitTitle.revert()
                  this.splitTitle = {}
                  this.iterationsArr = []
                  this.intervals = []
                  this.isAnimating = false
                }
              }
              chars[letterIndex].style.height = window.matchMedia(mediaQueries.largerThanMd).match ? '3.5rem' : '2rem'
              chars[letterIndex].style.color = this.getRandomFrom(this.colors)
              chars[letterIndex].innerHTML = this.getRandomFrom(this.symbols)
            }, this.speed)
          }, i * this.letterDelay)
        })
      }
    }
  }
</script>

<style lang="scss">

  .animated-title svg {
    width: auto;
    height: 100%;

    path {
      fill: currentColor;
    }

  }

</style>
