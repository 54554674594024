<template>
  <span
    class="e-checkbox"
    :class="{'is-disabled': disabled}"
  >
    <input
      :id="id"
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      class="checkbox"
      @change="$emit('input', $event.target.checked)"
    >
    <label
      class="w-full checkbox-label cursor-pointer"
      :for="id"
    >
      <span
        v-if="!required"
        class="checkbox-icon"
      >
        <q-icon
          :name="icon"
          :class="iconClasses"
        />
      </span>
      <slot />
      <span
        v-if="tooltip && required"
        class="info hidden lg:block absolute top-2 left-2 z-10 text-blue-500"
      >
        <q-icon
          name="info"
          class="text-base"
        />
        <div class="tooltip hidden">
          <tooltip :text="tooltip" />
        </div>
      </span>

    </label>
  </span>
</template>

<script>

  import Tooltip from '@/components/Tooltip'

  export default {
    components: { Tooltip },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      id: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        default: 'checkmark'
      },
      iconClasses: {
        type: String,
        default: 'text-xs'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      tooltip: {
        type: String,
        default: ''
      },
      required: {
        type: Boolean,
        default: false
      }
    }
  }

</script>

<style lang="scss">
.e-checkbox {
  @apply inline-block;

  input[type="checkbox"] {
    @apply absolute w-0 h-0 opacity-0;
  }

  &:hover:not(.is-disabled) {
    .checkbox-icon {
      @apply border-black;
    }
  }

  .checkbox-label {
    @apply inline-flex items-center;
  }

  .checkbox-icon {
    @apply flex rounded-sm items-center justify-center w-6 h-6 flex-grow-0 bg-white text-gray-200 border-2 mr-3;

    .q-icon {
      @apply flex;

      svg {
        width: 1em;
        height: 1em;

        path {
          fill: currentColor;
        }
      }
    }

    svg {
      opacity: 0;
      transform: scale(0.5);
      transition: transform 0.2s cubic-bezier(0.39, 0.575, 0.565, 1),
        opacity 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
    }
  }

  .checkbox:disabled + label {
    @apply cursor-not-allowed;
  }

  .checkbox:checked:not(:disabled) + label .checkbox-icon {
    @apply border-black text-black;

    svg {
      opacity: 1;
      transform: scale(1);
    }
  }

  .checkbox:checked:disabled + label .checkbox-icon {
    @apply border-gray-200;

    svg {
      opacity: 1;
      transform: scale(1);
    }
  }

  .checkbox:focus + label .checkbox-icon {
    // box-shadow: 0 0 0 0.125rem theme('colors.orange.500');
  }

  .info:hover .tooltip {
    display: block;
  }
}

.e-checkbox.is-disabled {
  @apply text-gray-200;
  user-select: none;

  .checkbox-icon {
    @apply border-gray-200;
  }

  .checkbox-label:hover .checkbox-icon {
  }
}
</style>
