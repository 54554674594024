<template>
  <div class="the-social-links">
    <ul class="flex">
      <li
        v-for="social in socialLinks"
        :key="social.fields.icon"
      >
        <a
          :href="social.fields.url"
          target="_blank"
          rel="noopener nofollow"
          :aria-label="`Follow us on ${social.fields.icon}`"
          class="text-white"
        >
          <q-icon :name="social.fields.icon" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>

  export default {
    props: {
      socialLinks: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style lang="scss">

  .the-social-links {

    li:not(:last-child) {
      @apply mr-4;
    }

  }

</style>
