
export const state = () => ({
  levels: [],
  selectedLevels: []
})

export const mutations = {
  SET_LEVELS (state, data) {
    state.levels = data
  },
  SET_SELECTED_LEVEL (state, level) {
    if (state.selectedLevels.includes(level)) {
      state.selectedLevels.splice(state.selectedLevels.indexOf(level), 1)
    } else {
      state.selectedLevels.push(level)
    }
  },
  SET_SELECTED_LEVELS (state, levels) {
    state.selectedLevels = levels
  }
}

export const getters = {
  parsedLevels (state) {
    if (!state.levels.length) return []
    return state.levels.map((level) => {
      return {
        title: level.fields.title,
        slug: level.fields.slug,
        id: level.sys.id
      }
    })
  },
  filteredLevels (state, getters) {
    if (!getters.parsedLevels.length) return []
    const sortingArray = ['VMBO', 'HAVO', 'VWO']
    const filtered = getters.parsedLevels.filter(level => sortingArray.includes(level.title))
    const sorted = filtered.sort(function (a, b) {
      return sortingArray.indexOf(a.title) - sortingArray.indexOf(b.title)
    })
    return sorted
  },
  levelOptions (state, getters) {
    if (!state.selectedLevels.length) return null
    const selected = getters.parsedLevels.filter((level) => {
      const exactMatch = state.selectedLevels.includes(level.title)
      const VMBO = state.selectedLevels.includes('VMBO') && level.title.includes('VMBO')
      return exactMatch || VMBO
    })
    const ids = selected.map(level => level.id)
    return { 'fields.level.sys.id[in]': ids.join(',') }
  }
}

export const actions = {
  async get ({ commit }) {
    const { items } = await this.$contentful.getEntries({
      content_type: 'level',
      order: 'fields.title'
    })
    commit('SET_LEVELS', items)
  }
}
