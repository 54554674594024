<template>
  <div
    v-if="logos && logos.length > 0"
    class="bg-white flex justify-center items-center gap-10 py-8 border-t border-gray-200"
  >
    <div
      v-for="logo in logos"
      :key="logo.id"
    >
      <img
        class="logo-image"
        :src="getImageSrc(logo.file.url)"
      >
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      logos: {
        required: true,
        type: Array,
      }
    },
    methods: {
      getImageSrc (fileUrl) {
        // sanity check, contentful returns file urls without url prefix (e.g. https)
        return fileUrl.startsWith('https:') ? fileUrl : `https:${fileUrl}`;
      }
    }
  }
</script>

<style>
  .logo-image {
    width: 60px;
    max-height: 60px;
    object-fit: contain;
  }
</style>
