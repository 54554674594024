import { modalModule, modalComponentsModule, notificationModule } from '@qikkeronline/qikker-ui/lib/store'

export const plugins = [modalModule, modalComponentsModule, notificationModule]

export const state = () => ({
  menuOpen: false,
  mainNavigation: {},
  footerNavigation: [],
  accountFooterNavigation: [],
  config: {}
})

export const mutations = {
  SET_MENU (state, data) {
    state.menuOpen = data
  },
  SET_MAIN_NAVIGATION (state, data) {
    state.mainNavigation = data
  },
  SET_FOOTER_NAVIGATION (state, data) {
    state.footerNavigation = data
  },
  SET_ACCOUNT_FOOTER_NAVIGATION (state, data) {
    state.accountFooterNavigation = data
  },
  SET_CONFIG (state, data) {
    state.config = data
  }
}

export const actions = {
  async nuxtServerInit ({ dispatch, commit }, { route, redirect }) {
    /*
    ** If the url starts with /secure we will redirect to Toegang for a
    ** no prompt auth check. If the user has a running session at Toegang a token will be fetched
    ** without user interaction. If user interaction is needed this will fail and the user would be
    ** redirected to the login page.
    *
    ** This is done here so nuxt won't check if the url is a valid route.
    */
    if (route.fullPath.startsWith('/mijnexamenbundel/secure')) {
      const returnUrl = route.fullPath.replace('/mijnexamenbundel/secure', '/mijnexamenbundel')
      const redirectUrl = process.env.API_URL + '/auth/authorize-noprompt?returnUrl=' + encodeURIComponent(returnUrl)
      return redirect(redirectUrl)
    }
    try {
      if (route && route.name && route.name.includes('subject-modal')) {
        commit('subjects/SET_MODAL_FIXED_BODY', false)
      }
      await Promise.all([
        dispatch('getNavigation'),
        dispatch('getConfig')
      ])
    } catch (e) {
      console.log(e)
    }
  },
  async nuxtClientInit ({ state, dispatch }, paramsObject) {
    if (Object.keys(state.config).length === 0) {
      await dispatch('nuxtServerInit', paramsObject)
    }
  },
  async getNavigation ({ commit }) {
    const { items } = await this.$contentful.getEntries({
      content_type: 'navigation',
      include: 2
    })
    const mainNav = items.find(item => item.fields.position === 'Main')
    commit('SET_MAIN_NAVIGATION', mainNav)
    const accountFooterNav = items.find(item => item.fields.position === 'Account Footer')
    commit('SET_ACCOUNT_FOOTER_NAVIGATION', accountFooterNav ? [accountFooterNav] : [])
    const footerNav = items.filter(item => item.fields.position !== 'Main' && item.fields.position !== 'Account Footer')
      .sort((a, b) => a.fields.position.slice(-1) - b.fields.position.slice(-1)) // compare Footer1 with Footer2, Footer3 by checking the last character
    commit('SET_FOOTER_NAVIGATION', footerNav)
  },
  async getConfig ({ commit }) {
    const { items } = await this.$contentful.getEntries({
      content_type: 'config'
    })
    if (items && items.length) commit('SET_CONFIG', items[0])
  },
  submitContactForm ({ dispatch }, formData) {
    return new Promise((resolve, reject) => {
      this.$axios.post('/v1/contact', formData)
        .then((res) => {
          dispatch('notifications/show', {
            type: 'success',
            text: 'Contact formulier succesvol verzonden.',
            closeButtonIconName: 'cross',
            closeTimeout: 4000
          }, { root: true })
          resolve(res)
        })
        .catch((err) => {
          dispatch('notifications/show', {
            type: 'error',
            text: 'Er is iets helaas niet goed gegaan, probeer het op een later moment.',
            closeButtonIconName: 'cross',
            closeTimeout: 4000
          }, { root: true })
          reject(err)
        })
    })
  }
}
