<template>
  <div
    class="e-select"
    :class="classes"
  >
    <label
      v-if="label"
      :for="id"
      class="label"
    >
      {{ label }}
    </label>
    <div class="select-container">
      <select
        :id="id"
        ref="input"
        :placeholder="placeholder"
        :disabled="disabled"
        :value="value"
        :options="options"
        v-bind="$attrs"
        @input="$emit('input', $event)"
        @blur="$emit('blur')"
        v-on="$listeners"
      >
        <slot>
          <option
            v-for="(item, index) in options"
            :key="index"
            :value="item"
          >
            {{ item }}
          </option>
        </slot>
      </select>
    </div>
    <div class="error-container">
      <slot name="error" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: [String, Number],
        default: ''
      },
      options: {
        type: Array,
        required: true
      },
      id: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      valid: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      classes () {
        return {
          'has-error': !this.valid,
          'has-selection': this.value
        }
      }
    }
  }

</script>

<style lang="scss">

.select-container {
  @apply bg-white;

  select {
    height: 3rem;
    @apply px-4;
    @apply py-2;
    @apply border-gray-200 border-2;
    @apply rounded-sm;

    &:hover,
    &:focus {
      @apply border-black;
    }
  }
}

.e-select.has-error {

}

.error-container {
  @apply text-red-500;

  > * {
    @apply pt-1;
  }
}

</style>
