import { cartGetters, productGetters } from '@propeller-commerce/propeller';
import { format, parseISO } from 'date-fns/esm';
import { nl } from 'date-fns/esm/locale';
import { productAttributes } from '~/constants/products.module';

export const wrapElements = function (elements, cssClass) {
  if (!elements.length) return;
  elements.forEach((el) => {
    const wrapper = document.createElement("div");
    wrapper.setAttribute("class", cssClass);
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
  });
};

/**
 * Level sort order for sorting the subjects on the following pages
 * - Step 4 on the onboarding page for teacher role
 * - Teacher's dashboard
 */
export const levelSortOrder = ["vmbo-b", "vmbo-k", "vmbo-gl-tl", "havo", "vwo"];

export const getSubjectsAlphabetical = (subjects) => {
  return [...subjects].sort(
    (a, b) =>
      a.name.toUpperCase().localeCompare(b.name.toUpperCase()) ||
      levelSortOrder.indexOf(a.levelName) - levelSortOrder.indexOf(b.levelName)
  );
};

/**
 * Capitalize string helper
 */
export const capitalize = (value) => {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * Check if a product can be ordered
 */
export const isOrderable = (product) => product?.isOrderable === "Y";

/**
 * Check if a product can be delivered right away
 */
export const isAvailable = (product) =>
  !!product?.inventory?.totalQuantity &&
  product.inventory.totalQuantity > 0 &&
  product.isOrderable === "Y";

const forAllProductItems = (testFunction) => (items) => {
  if (!items || items.length === 0) {
    return false;
  }
  return items.every(({ product }) => testFunction(product));
};

/**
 * Check if all products of a bundle can be ordered
 */
export const allBundleItemsOrderable = forAllProductItems(isOrderable);

/**
 * Check if all products of a bundle can be delivered right away
 */
export const allBundleItemsAvailable = forAllProductItems(isAvailable);

/**
 * Round to 2 decimals at most
 */
export const roundToTwoDecimals = (n) => (!n ? 0 : Math.round(n * 100) / 100);

/**
 * Get a number from 0 up to and including max, random or from an index
 */
export const getRandomNumberWithMax = (max = 0, index) => {
  if (index !== undefined) {
    return index % (max + 1);
  }
  return Math.floor(Math.random() * max);
};

/**
 * Get a blurred dummy card image for a freemium user
 */
export const getDummyCardImage = (index) =>
  require(`~/assets/img/cards/dummy${getRandomNumberWithMax(14, index)}.jpg`);

export const constructSubjectFilter = (sections, topics, parentTopics, showCheckedCards, difficulty, examTypes) => {
  let filterQuery = ''
  if (sections || topics) {
    filterQuery = "?";
  }
  if (sections) {
    sections.forEach((section) => {
      filterQuery =
        filterQuery +
        (!filterQuery.endsWith("?") ? "&" : "") +
        "sections[]=" +
        section;
    });
  }
  if (topics && topics.length > 0 && parentTopics && parentTopics.length === 1) {
    topics.forEach((topic) => {
      filterQuery = filterQuery + (!filterQuery.endsWith('?') ? '&' : '') + 'topicIds[]=' + topic;
    });
  } else if (parentTopics && parentTopics.length > 0) {
    parentTopics.forEach((topic) => {
      filterQuery = filterQuery + (!filterQuery.endsWith('?') ? '&' : '') + 'topicIds[]=' + topic;
    });
  }
  if (difficulty) {
    difficulty.forEach((difficultyLevel) => {
      filterQuery =
        filterQuery +
        (!filterQuery.endsWith("?") ? "&" : "") +
        "difficulty[]=" +
        difficultyLevel;
    });
  }
  if (examTypes) {
    examTypes.forEach((examType) => {
      filterQuery =
        filterQuery +
        (!filterQuery.endsWith("?") ? "&" : "") +
        "examTypes[]=" +
        examType;
    });
  }
  if (!showCheckedCards) {
    filterQuery =
      filterQuery +
      (!filterQuery.endsWith("?") ? "&" : "") +
      "hideLearned=" +
      !showCheckedCards;
  }

  return filterQuery;
};

// helper func because nuxt has a bug where it doesn't allow optional chaining inside the render
export const getLatestScore = (latestAttempt) => latestAttempt?.results?.grade;

export const getLocalizedDate = (iso) => {
  if (!iso) {
    return "";
  }
  return format(parseISO(iso), "EEEE d MMMM", { locale: nl });
};

/**
 * Return a copy of an object, in which all string values have been trimmed (shallow)
 */
export const trimAllStringValues = (object = {}) => {
  const result = { ...object };
  Object.keys(object).forEach((key) => {
    if (typeof object[key] === "string") {
      result[key] = object[key].trim();
    }
  });
  return result;
};

/**
 * Create slug from product name, replacing all spaces and slashes with a hyphen and transforming to all lowercase
 */
export const constructSlug = (s) =>
  s?.split(/\s|\//).join("-").toLowerCase() ?? "";

/**
 * Joins product names of product items in bundle together to show product type
 */
export const getBundleProductType = (bundleItems) =>
  bundleItems
    ?.map(
      (bundleItem) =>
        productGetters.getAttributes(bundleItem.product, [
          productAttributes.brand,
        ])[0]?.value
    )
    .join(" + ");
/**
 * Get a number with a min and/or max value
 */
export const getNumberInBounds = (n, min, max) => {
  if (min !== undefined && n < min) {
    return min;
  }
  if (max !== undefined && n > max) {
    return max;
  }
  return n;
};

/**
 * formats the total price incl btw from cartGetters.getTotals to an object containing the price excl btw & the total btw amount
 */
export const formatTotals = (totalPrice) => {
  const inclBtw = totalPrice;
  const exBtw = (inclBtw * 100) / (100 + 9);
  const btwAmount = inclBtw - exBtw;
  return {
    btwAmount,
    exBtw,
    inclBtw,
  };
};

/**
 * Formats examtype name (either 'oriëntatietoets' or 'examen') in the correct grammatical format based on input
 */
export const formatExamTypeText = (
  examType,
  capitalized = false,
  withArticle = false,
  withDemonstrativePronoun = false
) => {
  const isSelfTest = examType === "oriëntatietoets";
  let preposition = "";

  if (withArticle) {
    preposition = isSelfTest ? "de " : "het ";
  }

  if (withDemonstrativePronoun) {
    preposition = isSelfTest ? "deze " : "dit ";
  }

  return capitalized
    ? capitalize(preposition + examType)
    : preposition + examType;
};

const orderedLevelsList = [
  "vmbo",
  "vmbo-b",
  "vmbo-gl/tl",
  "vmbo-k",
  "vmbo-t",
  "havo",
  "vwo",
  "mbo",
  "hbo",
  "wo",
];

/**
 * Sort list of education levels (vmbo etc.) in a somewhat arbitrary sensible way
 */
export const sortLevelsList = (levelsList) => {
  const sorted = [];
  const rest = [];

  levelsList.forEach((level) => {
    if (!orderedLevelsList.includes(level.toLowerCase())) {
      rest.push(level);
      return;
    }
    sorted.push(level);
  });

  return [
    ...sorted.sort((a, b) => {
      const aIndex = orderedLevelsList.indexOf(a.toLowerCase());
      const bIndex = orderedLevelsList.indexOf(b.toLowerCase());
      if (aIndex > bIndex) return 1;
      if (aIndex < bIndex) return -1;
      return 0;
    }),
    ...rest.sort(),
  ];
};

/**
 * constructs product list to send as tracking data to Google Analytics
 */
export const setProductsListTracking = (products) =>
  products.map(setProductTracking);

/**
 * constructs a single product to send as tracking data to Google Analytics
 */
export const setProductTracking = (product, index) => {
  if (product.bundels) {
    return;
  }

  return {
    item_name: product.name,
    item_id: product.eanCode ?? product.id,
    price: Number(product.price.net.toFixed(2)),
    item_brand: "ThiemeMeulenhoff",
    quantity: product.quantity || null,
    item_category: product.productType?.value || "Examenbundel",
    item_category2: product.level,
    discount: product.price.discount
      ? Number(product.price.discount.toFixed(2))
      : 0.0,
    currency: "EUR",
    index,
  };
};

export const formatPrice = (price, showDecimals) => {
  const amount = Intl.NumberFormat("nl-NL", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(price);
  return showDecimals ? amount : amount.replace(",00", ",-");
};

export const calculateDiscountFromCart = (cart) => {
  return (cart?.total?.discountNet ?? 0) + cartGetters.getItems(cart.value).reduce((acc, item) => {
    return acc + (item.priceNet * item.quantity * (Math.abs(item.discountPercentage) / 100));
  }, 0);
}
