<template>
  <div
    class="rich-text-content"
    @click="handleClicks"
    v-html="content"
  >
  </div>
</template>

<script>

  import { wrapElements } from '@/utils/utils'

  export default {
    props: {
      content: {
        type: String,
        default: ''
      }
    },
    mounted () {
      const iframes = this.$el.querySelectorAll('iframe:not([height])')
      const tables = this.$el.querySelectorAll('.table')
      wrapElements(iframes, 'responsive-iframe')
      wrapElements(tables, 'responsive-table')
      if (process.browser) {
        window.MathJax?.typeset()
      }
    },
    methods: {
      // https://dennisreimann.de/articles/delegating-html-links-to-vue-router.html
      handleClicks (event) {
        // ensure we use the link, in case the click has been received by a subelement
        let { target } = event
        while (target && target.tagName !== 'A') target = target.parentNode
        // handle only links that occur inside the component and do not reference external resources
        if (target && target.matches(".rich-text-content a:not([href*='://'])") && target.href) {
          // some sanity checks taken from vue-router:
          // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
          const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
          // don't handle with control keys
          if (metaKey || altKey || ctrlKey || shiftKey) return
          // don't handle when preventDefault called
          if (defaultPrevented) return
          // don't handle right clicks
          if (button !== undefined && button !== 0) return
          // don't handle if `target="_blank"`
          if (target && target.getAttribute) {
            const linkTarget = target.getAttribute('target')
            if (/\b_blank\b/i.test(linkTarget)) return
          }
          // don't handle same page links/anchors
          const url = new URL(target.href)
          const to = url.pathname
          if (window.location.pathname !== to && event.preventDefault) {
            event.preventDefault()
            this.$router.push(to)
          }
        }
      }
    }
  }
</script>
