<template>
  <q-modal-base
    name="modal"
    :persistent="false"
    class="e-modal q-modal"
  >
    <div class="e-dialog relative bg-white shadow rounded p-6 md:p-12 md:pb-8 flex flex-col items-center">
      <!-- <button class="button-modal-close" @click="$store.dispatch('modal/close')">
        <q-icon name="cross" />
      </button> -->
      <h3 class="modal-title font-primary text-lg text-center pb-6 leading-7">
        {{ data.title }}
      </h3>
      <div class="modal-message text-center pb-8">
        <p v-html="data.message"></p>
      </div>
      <div
        v-for="(button, i) in buttons"
        :key="i"
      >
        <e-button
          :type="button.type"
          class="mb-4"
          @click="resolve(button.value)"
        >
          {{ button.label }}
        </e-button>
      </div>
    </div>
  </q-modal-base>
</template>

<script>
  import { mapState } from 'vuex'
  import { QModalBase } from '@qikkeronline/qikker-ui'

  export default {
    components: {
      QModalBase
    },
    data () {
      return {
        buttons: false
      }
    },
    computed: {
      ...mapState({
        data: state => state.modal.data
      })
    },
    watch: {
      data: {
        immediate: true,
        handler () {
          this.buttonsConstructor()
        }
      }
    },
    methods: {
      resolve (value) {
        this.data.resolve(value)
        this.$store.dispatch('modal/close')
      },
      buttonsConstructor () {
        this.buttons = [
          {
            label: this.data.confirm || 'OK',
            value: true,
            type: 'primary'
          }
        ]
        if (this.data.type === 'dialog') {
          this.buttons.push({
            label: this.data.cancel || 'CANCEL',
            value: false,
            type: 'quaternary'
          })
        }
      }
    }
  }
</script>

<style lang="scss">

  .e-dialog {
    @apply relative mx-auto;
    width: calc(100vw - 2rem);
    @screen md {
      width: 31.9375rem;
    }
  }

</style>
