<template>
  <div
    class="e-text-area"
    :class="classes"
  >
    <label
      v-if="label"
      :for="id"
      class="label"
    >
      {{ label }}
    </label>
    <div class="text-area-container">
      <textarea
        :id="id"
        ref="text-area"
        :placeholder="placeholder"
        :disabled="disabled"
        :value="value"
        v-bind="$attrs"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur')"
      />
    </div>
    <div class="error-container">
      <slot name="error" />
    </div>
  </div>
</template>

<script>

  export default {
    props: {
      value: {
        type: [String, Number],
        default: ''
      },
      id: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      valid: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      classes () {
        return {
          'has-error': !this.valid
        }
      }
    }
  }

</script>

<style lang="scss">

  .e-text-area {
    @apply relative;

    .label {
      @apply text-base pb-1 inline-block;
    }

    .text-area-container {
      @apply relative;

      textarea {
        @apply w-full rounded-xs border-black border-2 p-3 outline-none appearance-none;
        line-height: 1.5rem;

        &:placeholder-shown {
          @apply border-gray-200;
        }

        &:hover,
        &:focus
        {
          @apply border-black;
        }

        &:disabled {
          @apply border-gray-200 cursor-not-allowed;
        }

      }
    }

    &.has-error .text-area-container textarea {
      @apply border-red-500;
    }

    .error-container {
      @apply text-red-500;

      > * {
        @apply pt-1;
      }

    }

  }

</style>
