export const breakpoints = {
    sm: 40,
    md: 48,
    lg: 64,
    xl: 80,
    xxl: 90
}

export const mediaQueries = {
    smallerThanSm: `(max-width: ${breakpoints.sm - 0.0001}em)`,
    largerThanSm: `(min-width: ${breakpoints.sm}em)`,
    smallerThanMd: `(max-width: ${breakpoints.md - 0.0001}em)`,
    largerThanMd: `(min-width: ${breakpoints.md}em)`,
    smallerThanLg: `(max-width: ${breakpoints.lg - 0.0001}em)`,
    largerThanLg: `(min-width: ${breakpoints.lg}em)`,
    smallerThanXl: `(max-width: ${breakpoints.xl - 0.0001}em)`,
    largerThanXl: `(min-width: ${breakpoints.xl}em)`,
    smallerThanXxl: `(max-width: ${breakpoints.xxl - 0.0001}em)`,
    largerThanXxl: `(min-width: ${breakpoints.xxl}em)`
}
