
export const HeadMeta = function (entry) {
  const featuredImage = entry.featuredImage
    ? 'https:' + entry.featuredImage.fields.file.url + '?w=1200'
    : entry.productImage ? entry.productImage : ''

  return {
    title: entry.metaTitle || entry.title,
    titleTemplate: entry.titleTemplate || '%s | Examenbundel',
    meta: [
      { hid: 'og:title', property: 'og:title', content: entry.metaTitle || entry.title },
      { hid: 'og:description', property: 'og:description', content: entry.metaDescription },
      { hid: 'og:image', property: 'og:image', content: featuredImage },
      { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
      { hid: 'description', name: 'description', content: entry.metaDescription }
    ]
  }
}
