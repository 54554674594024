<template>
  <div
    class="app"
    :class="`route-${$route.name}`"
  >
    <the-header v-show="showHeaderFooter" />
    <nuxt />
    <the-footer
      v-show="showHeaderFooter"
      :navigation="accountFooterNavigation"
      :social-links="socialLinks"
    />
    <q-notifications
      position="top-center"
      anime-from="top"
    />
    <q-modal-components
      class="e-modal"
      :persistent="persistentModal"
      :close-button="false"
    />
    <e-dialog />
    <background-symbols />
    <cookie-notice />
  </div>
</template>

<script>

  import { mapState } from 'vuex'
  import { QModalComponents, QNotifications } from '@qikkeronline/qikker-ui'
  import TheHeader from '@/components/TheHeader'
  import TheFooter from '@/components/TheFooter'
  import EDialog from '@/components/EDialog'
  import BackgroundSymbols from '@/components/BackgroundSymbols'
  import CookieNotice from '@/components/CookieNotice'

  export default {
    components: {
      TheHeader,
      TheFooter,
      EDialog,
      QModalComponents,
      QNotifications,
      BackgroundSymbols,
      CookieNotice
    },
    data () {
      return {
        hideOnRoutes: ['onboarding']
      }
    },
    head () {
      return {
        title: 'Mijn',
        script: [
          {
            src: 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/3.2.0/es5/mml-svg.min.js'
          }
        ]
      }
    },
    computed: {
      ...mapState({
        accountFooterNavigation: state => state.accountFooterNavigation,
        config: state => state.config
      }),
      socialLinks () {
        if (!this.config.fields || !this.config.fields.socialLinks) return []
        return this.config.fields.socialLinks
      },
      showHeaderFooter () {
        return this.hideOnRoutes.some(name => !this.$route.name.includes(name))
      },
      persistentModal () {
        return this.$route.name === 'mijnexamenbundel'
      }
    },
    mounted () {
      // clear route queries
      this.$nextTick(() => {
        if (this.$route.query.error || this.$route.query.loggedIn) {
          this.$router.replace(this.$route.path)
        }
      })
    }
  }

</script>
