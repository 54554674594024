export default async function (store, routePath, redirect) {
  if (store.getters['auth/isLoggedIn'] || routePath.includes('login')) return
  try {
    await store.dispatch('nuxtClientInit')
    const user = await store.dispatch('auth/getUser')
    await store.dispatch('auth/getUserSubjects')
    setLevelClass(user.levels)

    if (!store.getters['auth/hasCompletedOnboarding'] && !routePath.includes('onboarding/stap-1')) {
      return redirect('/mijnexamenbundel/onboarding/stap-1')
    }
    if (routePath.includes('onboarding')) {
      return redirect('/mijnexamenbundel/account/profile')
    }
    if (routePath.includes('login')) {
      return redirect('/mijnexamenbundel')
    }
  } catch (e) {
    // redirect to login
    const from = routePath.includes('login') ? null : { from: routePath }
    return redirect({ path: '/mijnexamenbundel/login', query: from })
  }
}

const setLevelClass = (levelArray = []) => {
  // Levels key has changed to an array with levels because a teacher can choose multiple levels
  // In this case, we selected the first level from the array and return that classname.
  const levelClass = !levelArray.length ? 'user-level-havo' : `user-level-${levelArray[0].name.split('-')[0]}`
  document.documentElement.classList.add(levelClass)
}
