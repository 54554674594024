// we need the product filters to be exported as a module, so we can use the definition in middleware scripts

const productFilters = {
  educationType: 'list:examen_onderwijstype',
  subject: 'text:examen_schoolvak',
  brand: 'text:examen_merk'
}

const productAttributes = {
  educationType: 'EXAMEN_ONDERWIJSTYPE',
  subject: 'EXAMEN_SCHOOLVAK',
  brand: 'EXAMEN_MERK',
  preview: 'EXAMEN_INKIJKEXEMPLAAR',
}

module.exports = {
  productFilters,
  productAttributes
}
