<template>
  <e-button
    class="relative e-button-loading"
    :class="classes"
    v-on="$listeners"
  >
    <span class="content">
      <slot />
    </span>
    <transition name="button-loader">
      <q-loader v-if="loading" />
    </transition>
  </e-button>
</template>

<script>

  import { QLoader } from '@qikkeronline/qikker-ui'

  export default {
    components: {
      QLoader
    },
    props: {
      loading: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      classes () {
        return this.loading ? 'is-loading' : ''
      }
    }
  }

</script>

<style lang="scss">

  .e-button-loading {

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity .15s .25s, transform .15s .25s;
    }

    &.is-loading {
      .content {
        opacity: 0;
        transform: scale(.9);
        transition: opacity .25s, transform .25s;
      }
    }

    .q-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .button-loader-enter-active, .button-loader-leave-active {
      transition: opacity .25s, transform .25s;
    }
    .button-loader-enter, .button-loader-leave-to {
      transform: translate(-50%, -50%) scale(1.3);
    }

  }

</style>
