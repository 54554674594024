const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['close-modals'] = require('../middleware/close-modals.js')
middleware['close-modals'] = middleware['close-modals'].default || middleware['close-modals']

middleware['fetch-articles'] = require('../middleware/fetch-articles.js')
middleware['fetch-articles'] = middleware['fetch-articles'].default || middleware['fetch-articles']

middleware['mobile-menu'] = require('../middleware/mobile-menu.js')
middleware['mobile-menu'] = middleware['mobile-menu'].default || middleware['mobile-menu']

export default middleware
