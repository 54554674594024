<template>
  <div class="sales-notice w-full bg-blue-100 py-3 absolute">
    <div class="container mx-auto px-4 lg:px-8">
      <div class="flex relative items-center justify-center">
        <p class="text-blue-600 font-bold text-sm pr-4 md:pr-0">
          <slot />
        </p>
        <div
          class="absolute cursor-pointer hover:text-blue-500 transition-all right-0 flex items-center text-blue-600"
          @click="close"
        >
          <q-icon
            name="close"
            class="text-xs"
          ></q-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    setup (props, { emit }) {
      return {
        close: () => emit('dismiss')
      }
    }
  }
</script>
