<template>
  <div
    class="form-group"
    :class="{ 'has-error' : hasError }"
  >
    <slot />
    <div
      v-show="hasError"
      class="error-container text-red-500 mt-2 text-sm"
    >
      {{ errorLabel }}
    </div>
  </div>
</template>

<script>

  import ErrorExtractorMixin from '@/mixins/error-extractor-mixin'

  export default {
    mixins: [ErrorExtractorMixin]
  }
</script>

<style lang="scss">

  .form-group {

    &.has-error {
      .label {
        @apply text-red-500;
      }

      .input-container input {
        @apply border-red-500;
      }

      .dropdown-button {
        box-shadow: inset 0 0 0 0.125rem theme('colors.red.500');
      }

      .text-area-container textarea {
        @apply border-red-500;
      }
    }
  }

</style>
