/**
 * Note: If the role is a pupil, only single selects can be made.
 * 'selectedBaseLevelIds' and 'selectedSubLevelIds' will be set with
 * a single entry.
 *
 * If the role is set as teacher. The user will be able to multiselect
 * levels and sublevels hence the array.
 *
 */

export const state = () => ({
  educationRole: 'pupil',
  selectedBaseLevelIds: [], // 'e.g vmbo, havo, vwo'
  selectedSubLevelIds: [], // 'e.g vmbo-gl-tl, vmbo-k, vmbo-b'
  profile: '',
  selectedSubjectIds: [],
  selectedHasAccessIds: [],
  examYear: ''
})

export const mutations = {
  SET_SINGULAR (state, data) {
    state[data.field] = data.value !== state[data.field] ? data.value : ''
  },
  SET_ARRAY (state, data) {
    if (state[data.field].includes(data.value)) {
      state[data.field].splice(state[data.field].indexOf(data.value), 1)
    } else {
      state[data.field].push(data.value)
    }
  },
  SET_SELECTED_BASE_LEVEL (state, data) {
    if (state.selectedBaseLevelIds.includes(data[0])) {
      state.selectedBaseLevelIds.splice(state.selectedBaseLevelIds.indexOf(data[0]), 1)
    } else {
      state.selectedBaseLevelIds = data
    }
  },
  SET_SELECTED_SUB_LEVEL (state, data) {
    if (state.selectedSubLevelIds.includes(data[0])) {
      state.selectedSubLevelIds.splice(state.selectedSubLevelIds.indexOf(data[0]), 1)
    } else {
      state.selectedSubLevelIds = data
    }
  },
  SET_SELECTED_SUBJECTS (state, data) {
    state.selectedSubjectIds = data
  },
  SET_SELECTED_HAS_ACCESS_IDS (state, data) {
    state.selectedHasAccessIds = data
  },
  SET_SELECTED_EXAM_YEAR (state, data) {
    state.examYear = data
  }
}

export const getters = {
  requiredSubjectsIds (state, getters, rootState) {
    if (!rootState.subjects.primarySubjects.length) return []
    return rootState.subjects.primarySubjects.filter(subject => subject.required).map(item => item.id)
  },
  allSelectedLevels (state) {
    // Filter out 'vmbo' because we use the sublevels of vmbo to make the calls but
    // need it  in the base level array for the selection
    const baseLevels = state.selectedBaseLevelIds.filter(level => level !== 'vmbo')
    const subLevels = state.selectedSubLevelIds
    return [...baseLevels, ...subLevels]
  }
}

export const actions = {
  async submit ({ state, commit, dispatch, getters }) {
    try {
      // response is the user object
      await dispatch('auth/updateUserLevels', { levels: getters.allSelectedLevels }, { root: true })
      const user = await dispatch('auth/updateUser', {
        educationRole: state.educationRole,
        onboardingCompleted: true,
        profile: state.profile,
        examYear: state.examYear
      }, { root: true })

      if (user) {
        // Remove existing user-level class
        document.documentElement.classList.forEach((className) => {
          if (className.startsWith('user-level')) {
            document.documentElement.classList.remove(className)
          }
        })
        // Levels key has changed to an array with levels because a teacher can choose multiple levels
        // In this case, we selected the first level from the array and return that classname.
        const firstLevelNameAsClass = (levelArray = []) => {
          return !levelArray.length ? 'user-level-havo' : `user-level-${levelArray[0].name.split('-')[0]}`
        }
        document.documentElement.classList.add(firstLevelNameAsClass(user.levels))
      }

      await dispatch('auth/updateUserSubjects', { ids: state.selectedSubjectIds }, { root: true })
      await dispatch('auth/getUser', null, { root: true })
    } catch (err) {
      console.log(err)
    }
  }
}
