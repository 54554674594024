<template>
  <div
    class="e-select"
    :class="classes"
  >
    <label
      v-if="label"
      :for="id"
      class="label"
    >
      {{ label }}
    </label>
    <div class="select-container">
      <v-select
        :id="`select-${id}`"
        ref="input"
        :class="{
          'v-select vs--single': !multiple,
        }"
        :placeholder="placeholder"
        :disabled="disabled"
        :value="value"
        :options="options"
        :multiple="multiple"
        :searchable="false"
        v-bind="$attrs"
        @input="$emit('input', $event)"
        @blur="$emit('blur')"
      />
    </div>
    <div class="error-container">
      <slot name="error" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: [String, Number],
        default: ''
      },
      options: {
        type: Array,
        required: true
      },
      id: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      valid: {
        type: Boolean,
        default: true
      },
      multiple: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      classes () {
        return {
          'has-error': !this.valid,
          'has-selection': this.value
        }
      }
    }
  }

</script>

<style lang="scss">

.select-container {
  @apply bg-white;
}

.v-select {
  .vs__actions {
    padding: .25rem .75rem 0 0.1875rem;
  }
  .vs__dropdown-toggle {
    height: 3rem;
    @apply border-gray-200 border-2 #{!important};
    @apply rounded-sm #{!important};

    &:hover,
    &:focus {
      @apply border-black #{!important};
    }
  }

  input:placeholder-shown {
    @apply text-gray-300;
  }

  &.vs--open .vs__selected {
    top: 0.4375;
  }
}

.e-select.has-selection {
  .v-select {
    .vs__dropdown-toggle {
      @apply border-black #{!important};
    }
  }
}
.e-select.has-error {
  .v-select {
    .vs__dropdown-toggle {
      @apply border-red-500 #{!important};
    }
  }
}

.error-container {
  @apply text-red-500;

  > * {
    @apply pt-1;
  }
}

</style>
