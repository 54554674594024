<template>
  <div class="app">
    <the-header />
    <mobile-navigation />
    <nuxt />
    <footer-logos :logos="footerLogos" />
    <the-footer
      :navigation="footerNavigation"
      :social-links="socialLinks"
    />
    <q-notifications
      position="top-center"
      anime-from="top"
    />
    <q-modal-components
      :transition-modal="modalTransition"
      class="e-modal marketing-website-modal"
    >
      <template #close>
        <q-icon name="cross" />
      </template>
    </q-modal-components>
    <background-symbols />
    <cookie-notice />
  </div>
</template>

<script>

  import { mapState } from 'vuex'
  import { QModalComponents, QNotifications } from '@qikkeronline/qikker-ui'
  import TheHeader from '@/components/TheHeader'
  import TheFooter from '@/components/TheFooter'
  import MobileNavigation from '@/components/MobileNavigation'
  import BackgroundSymbols from '@/components/BackgroundSymbols'
  import { HeadMeta } from '@/utils/head-meta'
  import CookieNotice from '@/components/CookieNotice'
  import FooterLogos from '@/components/FooterLogos'

  export default {
    components: {
      TheHeader,
      TheFooter,
      MobileNavigation,
      QNotifications,
      QModalComponents,
      BackgroundSymbols,
      CookieNotice,
      FooterLogos
    },
    data () {
      return {
        footerLogos: []
      }
    },
    async fetch () {
      try {
        const { items } = await this.$nuxt.context.$contentful.getEntries({
          content_type: 'footerLogos'
        })
        // console.log("HELLO ITEMS", items)
        this.footerLogos = items[0]?.fields?.logos?.map(logo => ({
          file: logo.fields.file,
          id: logo.sys.id
        })) ?? [];
      } catch(err) {
        console.error(err);
      }
    },
    head () {
      return HeadMeta({
        title: 'Zeker slagen met onze examenbundels!',
        description: 'Jouw eindexamens haal je met Examenbundel, Samengevat, Examenidioom en Zeker Slagen! Oefen met echte examens en perfecte samenvattingen en ga vol vertrouwen het examen in. #geenexamenstress'
      })
    },
    computed: {
      ...mapState({
        footerNavigation: state => state.footerNavigation,
        config: state => state.config
      }),
      socialLinks () {
        if (!this.config.fields || !this.config.fields.socialLinks) return []
        return this.config.fields.socialLinks
      },
      modalTransition () {
        return this.$device.isMobile ? 'slide-bottom' : 'modal'
      }
    }
  }

</script>
