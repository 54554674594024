/* eslint-disable space-before-function-paren */
export function gtmSendEvent(
  eventCategory,
  eventPayload,
  eventLabel = false,
  usesEcommerce = false
) {
  if (!window?.dataLayer) {
    window.dataLayer = [];
  }
  if (usesEcommerce) {
    pushDataLayer({
      event: eventCategory,
      ...eventPayload,
    });
  } else {
    pushDataLayer({
      event: "customEvent",
      eventCategory,
      eventPayload,
      eventLabel,
    });
  }
}

function pushDataLayer(options) {
  window.dataLayer.push(options);
}
