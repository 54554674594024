export const validationMessages = {
  required: 'Dit veld is verplicht.',
  email: 'Voer een geldig e-mailadres in.',
  maxValue100: 'Waarde moet kleiner zijn dan 100.',
  sameAsPassword: 'Nieuw wachtwoord en herhaling wachtwoord komen niet overeen.',
  checkboxRequired: 'Dit veld is verplicht.'
}

export default {
  inject: {
    formValidation: { default: false },
    formServerErrors: { default: () => ({}) }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    validation: {
      type: Object,
      default: () => { }
    },
    serverErrors: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    preferredValidation () {
      if (Object.prototype.hasOwnProperty.call(this.$options.propsData, 'validation')) return this.validation
      return this.name ? this.formValidation[this.name] : this.validation
    },
    preferredServerErrors () {
      return this.name ? this.formServerErrors()[this.name] : this.serverErrors
    },
    error () {
      if (!(this.preferredValidation && this.preferredValidation.$error)) {
        return
      }
      let singleError
      Object.keys(this.preferredValidation).forEach((key) => {
        if (!key.startsWith('$') && typeof this.preferredValidation[key] === 'boolean' && !this.preferredValidation[key]) {
          singleError = {
            type: key,
            message: validationMessages[key]
          }
        }
      })
      return singleError
    },
    hasError () {
      return (this.error && this.error.length !== 0) || (this.preferredServerErrors && this.preferredServerErrors.length > 0)
    },
    errorLabel () {
      return (this.preferredServerErrors && this.preferredServerErrors.length > 0)
        ? this.preferredServerErrors[0]
        : (this.error ? this.error.message : '')
    }
  }
}
