require('dotenv').config()
const contentful = require('contentful')

// use default environment config for convenience
// these will be set via `env` property in nuxt.config.js

const config = {
  space: process.env.CTF_SPACE_ID,
  accessToken: process.env.CTF_CDA_ACCESS_TOKEN,
  environment: process.env.CTF_ENVIRONMENT,
  host: process.env.CTF_HOST,
  retryOnError: false,
  retryLimit: 1,
  logHandler: {
  }
}

export default (context, inject) => {
  // Inject $content in Vue, context and store.
  inject('contentful', contentful.createClient(config))
}

// export `createClient` to use it outside of nuxt
export const createClient = function () {
  return contentful.createClient(config)
}
