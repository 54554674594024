import Cookies from 'js-cookie'

export const state = () => ({
  cookie: null,
  showNotice: false,
  gdprPixel: false,
  selectedCategoriesValue: null,
  cookieCategories: [
    {
      id: 'complete',
      value: '1,2,3',
      label: 'Compleet',
      description: 'Met cookies Compleet werkt de website optimaal. Je websitebezoek wordt bijgehouden, zodat we onze website kunnen verbeteren en je binnen en buiten onze website passende aanbiedingen kunnen doen.'
    },
    {
      id: 'personal',
      value: '1,2',
      label: 'Persoonlijk',
      description: 'Met cookies Persoonlijk heb je functionele cookies die zorgen voor een goede werking van onze website. Hierbij horen ook cookies waarmee wij het bezoek aan onze website meten, analyseren en verbeteren. Zo kunnen we je binnen onze website op maat informatie bieden.'
    },
    {
      id: 'base',
      value: '1',
      label: 'Basis',
      description: 'De Basis cookies zijn onmisbaar voor onze website en om in te loggen. Zonder deze functionele cookies werkt de website minder goed. Je krijgt geen persoonlijke aanbiedingen met deze cookies.'
    }
  ]
})

export const mutations = {
  SET_COOKIE (state, data) {
    state.cookie = data
  },
  SHOW_NOTICE (state, data) {
    state.showNotice = data
  },
  SET_SELECTED_CATEGORIES_VALUE (state, data) {
    state.selectedCategoriesValue = data
  },
  SET_GDPR_PIXEL (state, data) {
    state.gdprPixel = data
  }
}

export const actions = {
  getCookie ({ commit }) {
    const cookie = Cookies.get('gdpr_cookie')
    if (!cookie) return false
    const cookieCategories = cookie.split(',').map(category => parseInt(category)).filter(category => category)
    const value = cookieCategories.join(',')
    commit('SET_SELECTED_CATEGORIES_VALUE', value)
    commit('SET_COOKIE', cookie)
  },
  setCookie ({ state, commit }) {
    const cookieContent = '*,' + state.selectedCategoriesValue + ',GTM:1'
    Cookies.set('gdpr_cookie', cookieContent, { expires: 365, path: '/' })
    commit('SET_COOKIE', cookieContent)
  }
}
