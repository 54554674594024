<template>
  <div class="cookie-notice-wrapper">
    <transition name="fade">
      <div
        v-if="showNotice"
        class="backdrop fixed top-0 left-0 w-full h-full"
      ></div>
    </transition>
    <transition :name="cookieModalTransition">
      <div
        v-if="showNotice"
        class="cookie-notice-modal"
      >
        <div class="cookie-notice-content md:text-center">
          <h3 class="font-primary text-lg pb-8 md:pb-10">
            Welke cookies?
          </h3>
          <q-radio
            :options="radioOptions"
            class="consent-radios"
            @radio-change="handleRadioChange"
          />
          <p
            class="pb-4"
            :class="selectedCategoriesValue === null ? 'text-red-500 font-bold' : ''"
          >
            {{ selectedCategoryContent }}
          </p>
          <button
            class="button default-transition text-white mb-6 md:mb-10"
            :disabled="selectedCategoriesValue === null"
            :class="selectedCategoriesValue === null ? 'bg-gray-500 hover:bg-gray-600' : 'bg-green-500 hover:bg-green-600'"
            @click="acceptCookie"
          >
            Accepteer
          </button>
          <p class="text-xs md:px-32">
            ThiemeMeulenhoff gebruikt cookies om ervoor te zorgen dat de website zo goed mogelijk werkt.
            <nuxt-link
              to="/privacy"
              class="text-blue-500 hover:text-blue-600 default-transition underline"
              @click.native="acceptCookie"
            >
              Lees meer.
            </nuxt-link>
          </p>
        </div>
      </div>
    </transition>
    <div
      v-show="gdprPixel"
      id="gdpr_cookie_notice_pixel"
    ></div>
  </div>
</template>

<script>

  import { mapState } from 'vuex'
  import { gtmSendEvent } from '@/utils/gtm-send-event'

  export default {
    computed: {
      ...mapState('gdpr', {
        cookie: state => state.cookie,
        cookieCategories: state => state.cookieCategories,
        showNotice: state => state.showNotice,
        gdprPixel: state => state.gdprPixel,
        selectedCategoriesValue: state => state.selectedCategoriesValue
      }),
      radioOptions () {
        return this.cookieCategories.map((category) => {
          category.name = 'cookie-categories'
          category.checked = category.value === this.selectedCategoriesValue
          return category
        })
      },
      cookieModalTransition () {
        return this.$device.isMobile ? 'slide-bottom' : 'fade-up'
      },
      selectedCategoryContent () {
        const selectedCategory = this.cookieCategories.find(category => category.value === this.selectedCategoriesValue)
        if (selectedCategory) {
          return selectedCategory.description
        } else {
          return 'Maak een keuze.'
        }
      }
    },
    mounted () {
      this.$store.dispatch('gdpr/getCookie')
      if (!this.cookie) {
        window.setTimeout(() => {
          this.$store.commit('gdpr/SHOW_NOTICE', true)
        }, 1500)
      }
    },
    methods: {
      handleRadioChange (value) {
        this.$store.commit('gdpr/SET_SELECTED_CATEGORIES_VALUE', value)
      },
      acceptCookie () {
        gtmSendEvent('cookie-notice', 'submit-choice', this.selectedCategoriesValue)
        this.$store.dispatch('gdpr/setCookie')
        this.$store.commit('gdpr/SHOW_NOTICE', false)
        this.$store.commit('gdpr/SET_GDPR_PIXEL', true)
      }
    }
  }
</script>

<style lang="scss">
.cookie-notice-wrapper {
  .backdrop {
    @apply z-50;
    background: rgba(black, 0.3);
  }

  .cookie-notice-modal {
    @apply fixed top-0 left-0 w-full h-full;
    @apply flex justify-center items-end z-50;

    @screen md {
      @apply items-center;
    }
  }

  .cookie-notice-content {
    @apply max-w-full max-h-full rounded-t shadow border border-gray-100 bg-white overflow-y-auto;
    @apply px-4 py-6;
    width: 41rem;

    @screen md {
      @apply rounded;
      @apply px-8 pt-8 pb-6;
    }
  }

  .consent-radios {
    @apply block;

    @screen md {
      @apply inline-flex;
    }

    .q-radio-container {
      @apply mt-0 mb-6 block;

      @screen md {
        @apply inline-flex;
      }
    }
  }
}

#gdpr_cookie_notice_pixel {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 1px;
  height: 1px;
  background: transparent;
}
</style>
