import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_55082072 from 'nuxt_plugin_sentryserver_55082072' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_6fc27e3f from 'nuxt_plugin_sentryclient_6fc27e3f' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_2eeeef95 from 'nuxt_plugin_plugin_2eeeef95' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_3fa11cab from 'nuxt_plugin_cookieuniversalnuxt_3fa11cab' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_83e2dcd0 from 'nuxt_plugin_pluginutils_83e2dcd0' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginseo_40f60aec from 'nuxt_plugin_pluginseo_40f60aec' // Source: ./nuxt-i18n/plugin.seo.js (mode: 'all')
import nuxt_plugin_pluginrouting_2ab118e3 from 'nuxt_plugin_pluginrouting_2ab118e3' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_cd4b9528 from 'nuxt_plugin_pluginmain_cd4b9528' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_webfontloader_684b7603 from 'nuxt_plugin_webfontloader_684b7603' // Source: ./webfontloader.js (mode: 'client')
import nuxt_plugin_deviceplugin_10bc70e4 from 'nuxt_plugin_deviceplugin_10bc70e4' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_axios_926039fa from 'nuxt_plugin_axios_926039fa' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_nuxtplugin04c833e2_2e86cdbf from 'nuxt_plugin_nuxtplugin04c833e2_2e86cdbf' // Source: ./nuxt.plugin.04c833e2.js (mode: 'all')
import nuxt_plugin_pluginsi18ncookies3c0ba9dd_3bac0c3f from 'nuxt_plugin_pluginsi18ncookies3c0ba9dd_3bac0c3f' // Source: ./plugins.i18n-cookies.3c0ba9dd.js (mode: 'all')
import nuxt_plugin_pluginse2etesting54fe4a94_ec9d62a4 from 'nuxt_plugin_pluginse2etesting54fe4a94_ec9d62a4' // Source: ./plugins.e2e-testing.54fe4a94.js (mode: 'all')
import nuxt_plugin_pluginslogger76a3c4ad_7e26d3a4 from 'nuxt_plugin_pluginslogger76a3c4ad_7e26d3a4' // Source: ./plugins.logger.76a3c4ad.js (mode: 'all')
import nuxt_plugin_pluginsssr998f53c2_dab5c060 from 'nuxt_plugin_pluginsssr998f53c2_dab5c060' // Source: ./plugins.ssr.998f53c2.js (mode: 'all')
import nuxt_plugin_pluginscontext4e8c9062_31982a59 from 'nuxt_plugin_pluginscontext4e8c9062_31982a59' // Source: ./plugins.context.4e8c9062.js (mode: 'all')
import nuxt_plugin_datefns_0f10d3e9 from 'nuxt_plugin_datefns_0f10d3e9' // Source: ./date-fns.js (mode: 'all')
import nuxt_plugin_gtm_3f38e2ce from 'nuxt_plugin_gtm_3f38e2ce' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_contentful_cbbecb4c from 'nuxt_plugin_contentful_cbbecb4c' // Source: ../plugins/contentful (mode: 'all')
import nuxt_plugin_initclient_ad36b038 from 'nuxt_plugin_initclient_ad36b038' // Source: ../plugins/init.client.js (mode: 'client')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_qikkerui_55f6ee86 from 'nuxt_plugin_qikkerui_55f6ee86' // Source: ../plugins/qikker-ui (mode: 'all')
import nuxt_plugin_globalcomponents_91a8526c from 'nuxt_plugin_globalcomponents_91a8526c' // Source: ../plugins/global-components (mode: 'all')
import nuxt_plugin_vuelidate_4345260a from 'nuxt_plugin_vuelidate_4345260a' // Source: ../plugins/vuelidate (mode: 'all')
import nuxt_plugin_veevalidate_6e5ad03a from 'nuxt_plugin_veevalidate_6e5ad03a' // Source: ../plugins/vee-validate (mode: 'all')
import nuxt_plugin_vueselect_0c6a19ce from 'nuxt_plugin_vueselect_0c6a19ce' // Source: ../plugins/vue-select (mode: 'client')
import nuxt_plugin_istouchclient_c500fca0 from 'nuxt_plugin_istouchclient_c500fca0' // Source: ../plugins/is-touch.client.js (mode: 'client')
import nuxt_plugin_instantsearchclient_1ed6cd68 from 'nuxt_plugin_instantsearchclient_1ed6cd68' // Source: ../plugins/instant-search.client.js (mode: 'client')
import nuxt_plugin_vueobservevisibility_66676af7 from 'nuxt_plugin_vueobservevisibility_66676af7' // Source: ../plugins/vue-observe-visibility.js (mode: 'all')
import nuxt_plugin_meta_7d87abe7 from 'nuxt_plugin_meta_7d87abe7' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"fade","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"htmlAttrs":{"lang":"nl"},"title":"Zeker slagen met onze examenbundels!","titleTemplate":"%s | Examenbundel","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"generator","content":"Vue Storefront 2"}],"link":[{"rel":"alternate icon","type":"image\u002Fpng","href":"\u002Ffavicon.png"},{"rel":"icon","type":"image\u002Fsvg+xml","href":"\u002Ffavicon.svg"},{"rel":"preload","as":"font","type":"font\u002Fwoff2","href":"\u002Ffonts\u002Fdin\u002Fdin.woff2","crossorigin":"anonymous"}],"style":[],"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);};w[y]('GTM-WXV7P5M')})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":"\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-WXV7P5M&\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"\u003E\u003C\u002Fiframe\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_55082072 === 'function') {
    await nuxt_plugin_sentryserver_55082072(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_6fc27e3f === 'function') {
    await nuxt_plugin_sentryclient_6fc27e3f(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_2eeeef95 === 'function') {
    await nuxt_plugin_plugin_2eeeef95(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_3fa11cab === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_3fa11cab(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_83e2dcd0 === 'function') {
    await nuxt_plugin_pluginutils_83e2dcd0(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseo_40f60aec === 'function') {
    await nuxt_plugin_pluginseo_40f60aec(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_2ab118e3 === 'function') {
    await nuxt_plugin_pluginrouting_2ab118e3(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_cd4b9528 === 'function') {
    await nuxt_plugin_pluginmain_cd4b9528(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_webfontloader_684b7603 === 'function') {
    await nuxt_plugin_webfontloader_684b7603(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_10bc70e4 === 'function') {
    await nuxt_plugin_deviceplugin_10bc70e4(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_926039fa === 'function') {
    await nuxt_plugin_axios_926039fa(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtplugin04c833e2_2e86cdbf === 'function') {
    await nuxt_plugin_nuxtplugin04c833e2_2e86cdbf(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsi18ncookies3c0ba9dd_3bac0c3f === 'function') {
    await nuxt_plugin_pluginsi18ncookies3c0ba9dd_3bac0c3f(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginse2etesting54fe4a94_ec9d62a4 === 'function') {
    await nuxt_plugin_pluginse2etesting54fe4a94_ec9d62a4(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginslogger76a3c4ad_7e26d3a4 === 'function') {
    await nuxt_plugin_pluginslogger76a3c4ad_7e26d3a4(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsssr998f53c2_dab5c060 === 'function') {
    await nuxt_plugin_pluginsssr998f53c2_dab5c060(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginscontext4e8c9062_31982a59 === 'function') {
    await nuxt_plugin_pluginscontext4e8c9062_31982a59(app.context, inject)
  }

  if (typeof nuxt_plugin_datefns_0f10d3e9 === 'function') {
    await nuxt_plugin_datefns_0f10d3e9(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_3f38e2ce === 'function') {
    await nuxt_plugin_gtm_3f38e2ce(app.context, inject)
  }

  if (typeof nuxt_plugin_contentful_cbbecb4c === 'function') {
    await nuxt_plugin_contentful_cbbecb4c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_initclient_ad36b038 === 'function') {
    await nuxt_plugin_initclient_ad36b038(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_qikkerui_55f6ee86 === 'function') {
    await nuxt_plugin_qikkerui_55f6ee86(app.context, inject)
  }

  if (typeof nuxt_plugin_globalcomponents_91a8526c === 'function') {
    await nuxt_plugin_globalcomponents_91a8526c(app.context, inject)
  }

  if (typeof nuxt_plugin_vuelidate_4345260a === 'function') {
    await nuxt_plugin_vuelidate_4345260a(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_6e5ad03a === 'function') {
    await nuxt_plugin_veevalidate_6e5ad03a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueselect_0c6a19ce === 'function') {
    await nuxt_plugin_vueselect_0c6a19ce(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_istouchclient_c500fca0 === 'function') {
    await nuxt_plugin_istouchclient_c500fca0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_instantsearchclient_1ed6cd68 === 'function') {
    await nuxt_plugin_instantsearchclient_1ed6cd68(app.context, inject)
  }

  if (typeof nuxt_plugin_vueobservevisibility_66676af7 === 'function') {
    await nuxt_plugin_vueobservevisibility_66676af7(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_7d87abe7 === 'function') {
    await nuxt_plugin_meta_7d87abe7(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
