import { getSubjectsAlphabetical } from '@/utils/utils'

const returnUrlbase = process.env.APP_URL

export const state = () => ({
  user: {},
  userSubjects: []
})

export const mutations = {
  SET_USER (state, data) {
    state.user = data
  },
  SET_USER_SUBJECTS (state, data) {
    state.userSubjects = data
  },
  UPDATE_USER_SUBJECTS_TEST_ATTEMPT (state, { subjectId, updatedTestAttempt }) {
    const updatedSubjectId = state.userSubjects.findIndex(subject => subject.id === subjectId)

    if (updatedSubjectId !== -1) {
      state.userSubjects[updatedSubjectId].latestTestAttempt = updatedTestAttempt
    }
  }
}

export const getters = {
  isLoggedIn (state) {
    return state.user !== null && Object.keys(state.user).length !== 0
  },
  hasCompletedOnboarding (state) {
    return state.user.onboardingCompleted || false
  },
  isTeacher (state) {
    return state.user.educationRole === 'teacher'
  },
  subscribedToNewsletter (state, getters) {
    if (!getters.isLoggedIn) return false
    return state.user.emailPreference === 'accepted'
  },
  userSubjectsHasAccess (state) {
    if (!state.userSubjects.length) return []
    return state.userSubjects.filter(subject => subject.stream && subject.stream.userHasAccess)
  },
  userSubjectsNoAccess (state) {
    if (!state.userSubjects.length) return []
    return state.userSubjects.filter(subject => (!subject.stream || !subject.stream.userHasAccess) && !subject.stream?.isLightStream)
  },
  userSubjectsByAlphabeticalThenLevel (state) {
    if (!state.userSubjects.length) return []
    return getSubjectsAlphabetical(state.userSubjects)
  },
  userLightStreamSubjects (state) {
    return state.userSubjects.filter(subject => subject.stream?.isLightStream)
  },
  userFullStreamSubjects (state, getters) {
    return getters.userSubjectsHasAccess.filter(subject => !(subject.stream && subject.stream.isLightStream))
  }
}

export const actions = {
  getUser ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/v1/users/me')
        .then((res) => {
          if (!res.data.user) return reject(new Error(`Could not fetch user, response: ${JSON.stringify(res.data)}`))
          commit('SET_USER', res.data.user)
          return resolve(res.data.user)
        })
        .catch((e) => {
          return reject(e)
        })
    })
  },
  async getUserSubjects ({ commit }) {
    const { data } = await this.$axios.get('/v1/users/me/subjects')
    commit('SET_USER_SUBJECTS', data)
  },
  async getRedirectUrl (context, returnUrl = '/mijnexamenbundel') {
    const { data } = await this.$axios.post(`/auth/authorize?returnUrl=${returnUrlbase}${returnUrl}`)
    return data.redirect_url
  },
  async updateUser ({ commit }, fields) {
    try {
      const { data } = await this.$axios.patch('/v1/users/me', fields)
      commit('SET_USER', data)
      return data
    } catch (e) {
      console.log(e)
    }
  },
  async updateUserLevels ({ commit }, levels) {
    try {
      const { data } = await this.$axios.patch('/v1/users/me/levels', levels)
      commit('SET_USER', data)
      return data
    } catch (e) {
      console.log(e)
    }
  },
  async updateUserSubjects ({ commit, dispatch }, ids) {
    try {
      const { data } = await this.$axios.post('/v1/users/me/subjects', ids)
      commit('SET_USER_SUBJECTS', data)
      dispatch('notifications/show', {
        type: 'success',
        text: 'Je hebt een vak toegevoegd.',
        closeButtonIconName: 'cross',
        closeTimeout: 4000
      }, { root: true })
    } catch (e) {
      console.log(e)
      dispatch('notifications/show', {
        type: 'error',
        text: 'Er is iets helaas niet goed gegaan, probeer het op een later moment.',
        closeButtonIconName: 'cross',
        closeTimeout: 4000
      }, { root: true })
    }
  },
  async deleteUserSubject ({ commit, dispatch }, id) {
    try {
      const { data } = await this.$axios.delete(`/v1/users/me/subjects/${id}`)
      commit('SET_USER_SUBJECTS', data)
      dispatch('notifications/show', {
        type: 'success',
        text: 'Je hebt een vak verwijderd.',
        closeButtonIconName: 'cross',
        closeTimeout: 4000
      }, { root: true })
    } catch (e) {
      console.log(e)
      dispatch('notifications/show', {
        type: 'error',
        text: 'Er is iets helaas niet goed gegaan, probeer het op een later moment.',
        closeButtonIconName: 'cross',
        closeTimeout: 4000
      }, { root: true })
    }
  },
  logout (context, returnUrl) {
    return new Promise((resolve, reject) => {
      if (!returnUrl) return reject(new Error('Please provide a return url'))
      return resolve(`${process.env.API_URL}/auth/logout?returnUrl=${returnUrlbase}${returnUrl}`)
    })
  },
  async deleteUser () {
    await this.$axios.delete('/v1/users/me')
  },
  // autologin - not in use now
  getRedirectNoPrompt (context, returnUrl) {
    return new Promise((resolve, reject) => {
      if (!returnUrl) return reject(new Error('Please provide a return url'))
      return resolve(`${process.env.API_URL}/auth/authorize-noprompt?returnUrl=${returnUrlbase}${returnUrl}`)
    })
  }
}
