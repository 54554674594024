// https://axios.nuxtjs.org/helpers
export default function (ctx) {
  const { $axios, store, redirect, $sentry } = ctx
  $axios.interceptors.response.use(null, (error) => {
    $sentry.captureException(error)
    const code = parseInt(error.response && error.response.status)
    if (code === 401) {
      store.commit('auth/SET_USER', {})
      redirect('/mijnexamenbundel/login')
    }
    return Promise.reject(error)
  })
}
