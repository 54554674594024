<template>
  <transition name="fade">
    <div
      v-show="showBg"
      class="background-symbols-container"
    >
      <div class="container relative h-full hidden md:block">
        <div class="absolute top-0 left-0 w-full h-full">
          <span
            v-for="(icon, idx) in icons"
            :key="idx + '-' + icon.name"
            class="bg-icon"
            :class="`text-${icon.color}-500`"
            :style="{ top: icon.top, right: icon.right, left: icon.left }"
          >
            <q-icon :name="icon.name" />
          </span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

  import { gsap } from 'gsap'

  export default {
    data () {
      return {
        showBg: true,
        iconAmount: 10,
        symbols: [
          'exclamation',
          'hashtag',
          'arrow',
          'bracket'
        ],
        colors: [
          'blue',
          'purple',
          'orange',
          'red',
          'green'
        ],
        icons: []
      }
    },
    mounted () {
      this.initIcons()
    },
    methods: {
      initIcons () {
        const symbolsLength = this.symbols.length
        const colorsLength = this.symbols.length
        for (let idx = 0; idx < this.iconAmount; idx++) {
          const left = Math.floor(gsap.utils.random(2, 8))
          const right = Math.floor(gsap.utils.random(2, 8))
          const randomSymbolIdx = Math.floor(gsap.utils.random(0, symbolsLength))
          const randomColorIdx = Math.floor(gsap.utils.random(0, colorsLength))
          const symbol = {
            name: this.symbols[randomSymbolIdx],
            color: this.colors[randomColorIdx],
            top: idx === 0 ? '5%' : (idx / this.iconAmount) * 100 + '%',
            left: this.isOdd(idx) ? left + '%' : null,
            right: this.isOdd(idx) ? null : right + '%'
          }
          // Check if last symbol is the same as new. If so then grab another symbol
          const iconsArrLength = this.icons.length
          let newRandomSymbolIdx
          if (iconsArrLength < 1) {
            this.icons.push(symbol)
          } else {
            while (this.icons[iconsArrLength - 1].name === symbol.name) {
              newRandomSymbolIdx = Math.floor(gsap.utils.random(0, symbolsLength))
              symbol.name = this.symbols[newRandomSymbolIdx]
            }
            this.icons.push(symbol)
          }
        }
      },
      isOdd (num) {
        return (num % 2) === 1
      }
    }
  }
</script>

<style lang="scss">

  .background-symbols-container {
    @apply absolute left-0 top-0 w-full pointer-events-none;
    height: calc(100% - 6.25rem);
    top: 6.25rem;
    z-index: -1;

    .bg-icon {
      @apply absolute;
    }

    @at-root .route-mijnexamenbundel-account-profile {
      .background-symbols-container {
        height: calc(100% - 12.5rem);
        top: 12.5rem;
      }
    }

    @at-root .route-mijnexamenbundel-account-subjects-and-books {
      .background-symbols-container {
        height: calc(100% - 12.5rem);
        top: 12.5rem;
      }
    }

    @at-root .route-mijnexamenbundel-id-slug {
      .background-symbols-container {
        height: calc(100% - 31.25rem);
        top: 31.25rem;
      }
    }

    @at-root .route-mijnexamenbundel-article-articleId-articleSlug {
      .background-symbols-container {
        height: calc(100% - 18.75rem);
        top: 18.75rem;
      }
    }

  }

</style>
