<template>
  <footer class="the-footer text-white">
    <div class="footer-top bg-black py-5">
      <div class="container px-4 md:px-8">
        <div class="flex flex-wrap -mx-4">
          <div class="w-full md:w-3/12 py-5">
            <div class="flex md:flex-col">
              <div class="w-1/2 px-4 md:w-full md:mb-10">
                <nuxt-link
                  to="/"
                  aria-label="Examenbundel"
                >
                  <q-svg name="examenbundel-logo" />
                </nuxt-link>
              </div>
              <div class="w-1/2 px-4 md:w-full">
                <the-social-links :social-links="socialLinks" />
              </div>
            </div>
          </div>
          <div class="w-full md:w-9/12">
            <div class="flex flex-wrap">
              <div
                v-for="(footerNav, i) in navigation"
                :key="i"
                class="w-1/2 md:w-1/3 px-4"
              >
                <ul
                  v-if="footerNav.fields.navigationItems"
                  class="footer-menu py-5"
                >
                  <li v-if="isAccountFooter(footerNav.fields.position) && i === 0">
                    <a
                      href="/"
                      target="_blank"
                    >
                      <div class="flex items-center">
                        Examenbundel
                        <q-icon
                          name="external"
                          class="text-base ml-1"
                        />
                      </div>
                    </a>
                  </li>
                  <li
                    v-for="item in footerNav.fields.navigationItems"
                    :key="item.sys.id"
                  >
                    <nuxt-link
                      v-if="isRelativeUrl(item.fields.link)"
                      :to="item.fields.link"
                    >
                      {{ item.fields.title }}
                    </nuxt-link>
                    <a
                      v-else
                      :href="item.fields.link"
                      target="blank"
                    >
                      {{ item.fields.title }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom bg-gray-800">
      <div class="container px-4 md:px-8">
        <div class="flex flex-col md:flex-row flex-wrap items-start content-left md:items-center py-2 md:py-4">
          <p class="py-2 w-1/2 md:w-auto">
            Copyright © {{ year }} ThiemeMeulenhoff.
          </p>
          <img
            src="~/assets/svg/thieme-logo.svg"
            class="py-2 ml-0 md:ml-2"
            alt=""
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

  import TheSocialLinks from '@/components/TheSocialLinks'
  import isRelativeUrl from '@/mixins/isRelativeUrl'

  export default {
    components: {
      TheSocialLinks
    },
    mixins: [isRelativeUrl],
    props: {
      navigation: {
        type: Array,
        default: () => []
      },
      socialLinks: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        year: '2020'
      }
    },
    mounted () {
      this.year = new Date().getFullYear()
    },
    methods: {
      isAccountFooter (str) {
        if (typeof str !== 'string') return false
        return str === 'Account Footer'
      }
    }
  }
</script>

<style lang="scss">

  .the-footer {
    @apply relative z-10;

    .q-svg-examenbundel-logo {
      width: 7.0625rem;
      max-width: 113px;
      path {
        fill: theme('colors.white');
      }
    }

    .footer-menu {

      li:not(:last-child) {
        @apply pb-3;
      }

      a {
        @apply font-bold text-white relative inline-block pb-1;

        &:after {
          content: '';
          @apply absolute left-0 bottom-0 w-full;
          height: 0.125rem;
          background-color: currentColor;
          transform: scaleX(0);
          transition: transform .25s ease(easeOut);
        }

        &:hover:after {
          transform: scaleX(1);
        }

      }

    }

  }

</style>
