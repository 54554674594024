import Vue from 'vue'
import EButton from '@/components/EButton.vue'
import EButtonLoading from '@/components/EButtonLoading.vue'
import EInput from '@/components/EInput.vue'
import ETextArea from '@/components/ETextArea.vue'
import ECheckbox from '@/components/ECheckbox.vue'
import AnimatedTitle from '@/components/AnimatedTitle'
import RichTextContent from '@/components/RichTextContent'
import FormGroup from '@/components/FormGroup'
import FormWrapper from '@/components/FormWrapper'
import ERadio from '@/components/ERadio'
import ESelect from '@/components/ESelect'
import ESelect2 from '@/components/ESelect2'

Vue.component('EButton', EButton)
Vue.component('EButtonLoading', EButtonLoading)
Vue.component('EInput', EInput)
Vue.component('ETextArea', ETextArea)
Vue.component('ECheckbox', ECheckbox)
Vue.component('ERadio', ERadio)
Vue.component('ESelect', ESelect)
Vue.component('ESelect2', ESelect2)
Vue.component('AnimatedTitle', AnimatedTitle)
Vue.component('RichTextContent', RichTextContent)
Vue.component('FormGroup', FormGroup)
Vue.component('FormWrapper', FormWrapper)
