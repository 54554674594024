
export const state = () => ({
  articles: {},
  articlesPerPage: 11,
  categories: []
})

export const mutations = {
  SET_ARTICLES (state, data) {
    state.articles = data
  },
  SET_CATEGORIES (state, data) {
    state.categories = data
  }
}

export const getters = {
  totalPages (state) {
    if (Object.keys(state.articles).length === 0) return 0
    return Math.ceil(state.articles.total / state.articlesPerPage)
  },
  currentPage (state) {
    if (Object.keys(state.articles).length === 0) return 0
    return Math.ceil(state.articles.skip / state.articlesPerPage) + 1
  }
}

export const actions = {
  async getArticles ({ state, commit }, { mutate = true, page = 1, category = null }) {
    const skipNum = (page - 1) * state.articlesPerPage
    let categoryParams = null
    if (category) {
      categoryParams = {
        'fields.category.sys.contentType.sys.id': 'category',
        'fields.category.fields.slug[in]': category
      }
    }
    const articles = await this.$contentful.getEntries({
      content_type: 'article',
      order: '-fields.publishDate',
      limit: state.articlesPerPage,
      skip: skipNum,
      ...categoryParams
    })
    if (mutate) {
      commit('SET_ARTICLES', articles)
    }
    return articles
  },
  async getCategories ({ commit, dispatch }) {
    const { items } = await this.$contentful.getEntries({
      content_type: 'category',
      order: 'fields.title'
    })
    const promises = []
    items.forEach((item) => {
      promises.push(dispatch('getArticles', { mutate: false, category: item.fields.slug }))
    })
    const results = await Promise.all(promises)
    const categoriesWithResults = results.filter(result => result.total).map(item => item.includes.Entry[0].fields.slug)
    const finalResults = items.filter(item => categoriesWithResults.includes(item.fields.slug))
    commit('SET_CATEGORIES', finalResults)
  }
}
