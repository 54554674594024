
import merge from '~lodash.mergewith'
import { init } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F87d14ec77e8f4b66ae29d380bd470279@o1127103.ingest.sentry.io\u002F6330821",
    environment:"prod",
    debug:false,
    autoSessionTracking:true,
    release:"examenbundel-frontend@undefined",
    sourceMapStyle:"hidden-source-map",
    tracing:{"tracesSampleRate":0.02,"vueOptions":{"tracing":true,"tracingOptions":{"hooks":["activate","mount","update"],"timeout":2000,"trackComponents":true}}},
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
  ]

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
