import { isAfter, parseISO } from "date-fns";
import { levelSortOrder } from "@/utils/utils";

export const state = () => ({
  subjects: [],
  primarySubjects: [],
  currentSubject: {},
  currentSubjectSection: "",
  currentSubjectLearnCards: {},
  currentSubjectLearnLoading: false,
  currentSubjectLearnShowCheckedCards: false,
  currentSubjectProgressCards: [],
  currentSubjectUsefulCards: [],
  currentCard: [],
  selectedTopics: [],
  selectedParentTopics: [],
  selectedSections: [],
  selectedExamTypes: [],
  selectedDifficultyLevels: [],
  subjectTest: {},
  subjectTestAnswers: [],
  modalFixedBody: true,
  unfilteredCards: [],
});

export const mutations = {
  SET_UNFILTERED_CARDS (state, cards) {
    state.unfilteredCards = cards;
  },
  SET_SUBJECTS (state, data) {
    state.subjects = data;
  },
  SET_PRIMARY_SUBJECTS (state, data) {
    state.primarySubjects = data;
  },
  SET_CURRENT_SUBJECT (state, data) {
    state.currentSubject = data;
  },
  SET_CURRENT_SUBJECT_SECTION (state, data) {
    state.currentSubjectSection = data;
  },
  SET_CURRENT_SUBJECT_LEARN_CARDS (state, data) {
    state.currentSubjectLearnCards = data;
  },
  SET_CURRENT_SUBJECT_LEARN_LOADING (state, data) {
    state.currentSubjectLearnLoading = data;
  },
  SET_CURRENT_SUBJECT_LEARN_SHOW_CHECKED_CARDS (state, data) {
    state.currentSubjectLearnShowCheckedCards = data;
  },
  SET_CURRENT_SUBJECT_PROGRESS_CARDS (state, data) {
    state.currentSubjectProgressCards = data;
  },
  SET_CURRENT_SUBJECT_USEFUL_CARDS (state, data) {
    state.currentSubjectUsefulCards = data;
  },
  SET_CURRENT_CARD (state, data) {
    state.currentCard = data;
  },
  SET_SELECTED_TOPIC_FILTERS (state, topics) {
    state.selectedTopics = topics;
  },
  SET_SELECTED_PARENT_TOPIC_FILTERS (state, parentTopics) {
    state.selectedParentTopics = parentTopics;
  },
  SET_SELECTED_SECTION_FILTERS (state, sections) {
    state.selectedSections = sections;
  },
  SET_SELECTED_DIFFICULTY_FILTERS (state, difficultyLevels) {
    state.selectedDifficultyLevels = difficultyLevels;
  },
  SET_SELECTED_EXAM_TYPE_FILTERS (state, examTypeFilters) {
    state.selectedExamTypes = examTypeFilters;
  },
  SET_MODAL_FIXED_BODY (state, data) {
    state.modalFixedBody = data;
  },
  SET_SUBJECT_TESTS (state, data) {
    state.subjectTests = data;
  },
  SET_SUBJECT_TEST (state, data) {
    state.subjectTest = data;
  },
  SET_SUBJECT_TEST_RESULT (state, latestAttempt) {
    state.subjectTest = {
      ...state.subjectTest,
      latestAttempt,
    };
  },
  SET_SUBJECT_TEST_ANSWERS (state, answers) {
    state.subjectTestAnswers = answers;
  },
  UPDATE_CURRENT_SUBJECT (state, { testId, updatedTestAttempt }) {
    const updatedTestIndex = state.currentSubject.stream.tests?.findIndex(
      (test) => test.id === testId
    );
    if (updatedTestIndex !== -1) {
      state.currentSubject.stream.tests[updatedTestIndex].latestAttempt =
        updatedTestAttempt;
    }

    state.currentSubject.latestTestAttempt = updatedTestAttempt;
  },
  UPDATE_NEW_CONTENT_STATUS_TOPIC (state, cardId) {
    if (state.currentSubjectSection === "") {
      const foundCard = state.currentSubjectProgressCards.cards.find(
        (card) => card.id === cardId
      );
      if (foundCard) {
        const cardTopicIds = foundCard.topics.map((card) => card.id);
        cardTopicIds.forEach((topicId) => {
          const foundTopicIndex =
            state.currentSubjectProgressCards.availableTopics.findIndex(
              (topic) => topic.id === topicId
            );
          state.currentSubjectProgressCards.availableTopics[
            foundTopicIndex
          ].newContent = false;
        });
      }
    } else if (state.currentSubjectSection === "leren-en-oefenen") {
      const foundCard = state.currentSubjectLearnCards.cards.find(
        (card) => card.id === cardId
      );
      if (foundCard) {
        const cardTopicIds = foundCard.topics.map((card) => card.id);
        cardTopicIds.forEach((topicId) => {
          const foundTopicIndex =
            state.currentSubjectLearnCards.availableTopics.findIndex(
              (topic) => topic.id === topicId
            );
          state.currentSubjectLearnCards.availableTopics[
            foundTopicIndex
          ].newContent = false;
        });
      }
    } else if (state.currentSubjectSection === "ook-handig") {
      const foundCard = state.currentSubjectUsefulCards.cards.find(
        (card) => card.id === cardId
      );
      if (foundCard) {
        const cardTopicIds = foundCard.topics.map((card) => card.id);
        cardTopicIds.forEach((topicId) => {
          const foundTopicIndex =
            state.currentSubjectUsefulCards.availableTopics.findIndex(
              (topic) => topic.id === topicId
            );
          state.currentSubjectUsefulCards.availableTopics[
            foundTopicIndex
          ].newContent = false;
        });
      }
    }
  },
  UPDATE_NEW_CONTENT_STATUS_CARD (state, cardId) {
    if (state.currentSubjectSection === "") {
      const foundCardIndex = state.currentSubjectProgressCards.cards.findIndex(
        (card) => card.id === cardId
      );
      state.currentSubjectProgressCards.cards[
        foundCardIndex
      ].newContent = false;
    } else if (state.currentSubjectSection === "leren-en-oefenen") {
      const foundCardIndex = state.currentSubjectLearnCards.cards.findIndex(
        (card) => card.id === cardId
      );
      state.currentSubjectLearnCards.cards[foundCardIndex].newContent = false;
    } else if (state.currentSubjectSection === "ook-handig") {
      const foundCardIndex = state.currentSubjectUsefulCards.cards.findIndex(
        (card) => card.id === cardId
      );
      state.currentSubjectUsefulCards.cards[foundCardIndex].newContent = false;
    }
  },
  UPDATE_COMPLETED_CARDS (state, { cardId, topicsToUpdate, userHasLearned }) {
    let tmpCards = [];
    if (state.currentSubjectSection === "") {
      tmpCards = state.currentSubjectProgressCards;
    } else if (state.currentSubjectSection === "leren-en-oefenen") {
      tmpCards = state.currentSubjectLearnCards;
    } else if (state.currentSubjectSection === "ook-handig") {
      tmpCards = state.currentSubjectUsefulCards;
    }
    if (tmpCards !== []) {
      for (const [cardKey, card] of Object.entries(tmpCards.cards)) {
        if (card.id === cardId) {
          tmpCards.cards[cardKey].userHasLearned = !userHasLearned;
        }
      }
      if (tmpCards !== state.current) {
        state.currentSubjectLearnCards = tmpCards;
      }
    }
    /* The line below is disabled for eslint prefer-const because it throws an error ('tmpCards' is never reassigned. Use 'const' instead  prefer-const) even though we modify the tmpCards variable */
    let tmpTopics =
      state.currentSubjectLearnCards &&
      state.currentSubjectLearnCards.availableTopics
        ? state.currentSubjectLearnCards.availableTopics
        : []; // eslint-disable-line prefer-const
    if (topicsToUpdate !== [] && tmpTopics !== []) {
      topicsToUpdate.forEach((topicToUpdate) => {
        for (const [topicKey, topic] of Object.entries(tmpTopics)) {
          if (topic.id === topicToUpdate.id) {
            if (!userHasLearned) {
              if (
                tmpTopics[topicKey].learnedCards <
                tmpTopics[topicKey].totalLearnableCards
              ) {
                tmpTopics[topicKey].learnedCards =
                  tmpTopics[topicKey].learnedCards + 1;
              }
            } else if (tmpTopics[topicKey].learnedCards > 0) {
              tmpTopics[topicKey].learnedCards =
                tmpTopics[topicKey].learnedCards - 1;
            }
          }
        }
      });
    }
  },
  RESET_FILTERS (state) {
    state.selectedTopics = [];
    state.selectedParentTopics = [];
    state.selectedSections = [];
    state.selectedExamTypes = [];
    state.selectedDifficultyLevels = [];
  },
};

export const getters = {
  withoutUserSubjects (state, getters, rootState) {
    if (!state.subjects.length || !rootState.auth.userSubjects) {
      return state.subjects;
    }
    return state.subjects.filter((subject) => {
      return !rootState.auth.userSubjects.some(
        (userSubject) => subject.id === userSubject.id
      );
    });
  },
  subjectsByLevel (state) {
    if (!state.subjects.length) return [];
    // 1. Grab unique levels from subjects array
    const uniqueLevels = state.subjects
      .map((subject) => {
        return subject.levelName;
      })
      .filter((value, idx, self) => self.indexOf(value) === idx);
    // 2. For each unique level, create a new object filled with its corresponding subjects
    //    Then return the unique levels as an array.
    const allLevels = [];
    uniqueLevels.forEach((level) => {
      const filteredSubjectsPerLevel = state.subjects.filter(
        (subject) => subject.levelName === level
      );
      const obj = {
        levelName: level,
        subjects: filteredSubjectsPerLevel,
      };
      allLevels.push(obj);
    });
    return allLevels.sort(
      (a, b) =>
        levelSortOrder.indexOf(a.levelName) -
        levelSortOrder.indexOf(b.levelName)
    );
  },
  downloadableCards (state) {
    return state.currentSubjectProgressCards?.cards?.filter(
      (card) => card.isDownload === true
    );
  },
  subjectBaseUrl (state) {
    return `/mijnexamenbundel/${state.currentSubject?.id}/${state.currentSubject?.slug}`;
  },
  subjectLatestScore (state) {
    return state.currentSubject.latestTestAttempt?.results?.grade;
  },
  checkedTests (state) {
    return state.currentSubject.stream?.tests?.filter(
      (test) => test.latestAttempt !== null
    );
  },
  subjectAvailableTopics (state) {
    return state.currentSubjectLearnCards?.availableTopics?.map(
      ({ id, name }) => {
        return {
          id,
          name,
        };
      }
    );
  },
  latestResultsByTopic (state, getters) {
    if (getters.checkedTests.length > 0) {
      const result = getters.checkedTests.reduce((acc, curr) => {
        const currIsAfter = isAfter(
          parseISO(curr.latestAttempt.createdAt),
          parseISO(acc.latestAttempt.createdAt)
        );
        if (currIsAfter) {
          return curr;
        }
        return acc;
      });
      return result?.latestAttempt?.results?.perTopic;
    }
    return undefined;
  },
  latestTestAttempt (state) {
    return state.currentSubject.latestTestAttempt;
  },
  latestSubjectTestAttempt (state) {
    return state.subjectTest.latestAttempt;
  },
  userHasAccessToCurrentSubject (state, getters, rootState, rootGetters) {
    const currentSubjectId = state.currentSubject.id;
    return (
      rootGetters["auth/userSubjectsHasAccess"] &&
      rootGetters["auth/userSubjectsHasAccess"].some(
        (subject) => subject.id === currentSubjectId
      )
    );
  },
  selectedSectionFilterNames (state) {
    return state.selectedSections.map((section) => section.name).join();
  },
  correctionCard (state) {
    if (
      state.currentSubject.stream.correctionsCardId &&
      state.currentSubjectUsefulCards?.cards
    ) {
      return state.currentSubjectUsefulCards.cards.find(
        (card) => card.id === state.currentSubject.stream.correctionsCardId
      );
    }
    return undefined;
  },
  hasCorrection (state, getters) {
    return !!getters.correctionCard;
  },
  latestTestAttemptTopicResultsMap (state, getters) {
    if (getters.latestTestAttempt?.results?.perTopic?.length > 0) {
      return getters.latestTestAttempt?.results?.perTopic?.map(
        ({ topic, percentage, isUncertain }) => {
          const { id, name } = topic;
          return {
            id,
            name,
            isUncertain,
            score: percentage,
          };
        }
      );
    }

    return null;
  },
  latestSubjectTestAttemptTopicResultsMap (state, getters) {
    if (getters.latestSubjectTestAttempt?.results?.perTopic?.length > 0) {
      return getters.latestSubjectTestAttempt?.results?.perTopic?.map(
        ({ topic, percentage, isUncertain }) => {
          const { id, name } = topic;
          return {
            id,
            name,
            isUncertain,
            score: percentage,
          };
        }
      );
    }

    return null;
  },
};

export const actions = {
  async get ({ commit, rootState }, levels) {
    const allLevels = [];
    try {
      for (let idx = 0; idx < levels.length; idx++) {
        const { data } = await this.$axios.get(`/v1/subjects/${levels[idx]}`);
        allLevels.push(data);
      }
      commit("SET_SUBJECTS", allLevels.flat());
    } catch (err) {
      console.error(err);
    }
  },
  async getProfileSubjects ({ state, commit }, { level, profile }) {
    try {
      const { data } = await this.$axios.get(
        `/v1/subjects/${level}/${profile}`
      );
      commit("SET_PRIMARY_SUBJECTS", data);
    } catch (err) {
      console.error(err);
    }
  },
  async getSubject ({ state, commit }, id) {
    try {
      const { data } = await this.$axios.get(`/v1/subject/${id}`);
      commit("SET_CURRENT_SUBJECT", data);
    } catch (err) {
      console.error(err);
    }
  },
  async getSubjectCards ({ state, commit }, { id, filterQuery, page = "index" }) {
    try {
      const { data } = await this.$axios.get(`/v1/subject/${id}/cards${filterQuery}`);
      return new Promise((resolve) => {
        setTimeout(() => {
          commit("SET_CURRENT_SUBJECT_LEARN_LOADING", false);
          if (page === "index") {
            commit("SET_CURRENT_SUBJECT_LEARN_CARDS", data);
            commit("SET_CURRENT_SUBJECT_USEFUL_CARDS", data);
          } else if (page === "progress") {
            commit("SET_CURRENT_SUBJECT_PROGRESS_CARDS", data);
          } else if (page === "general") {
            commit("SET_CURRENT_SUBJECT_USEFUL_CARDS", data);
          }
          resolve(data);
        }, 300);
      });
    } catch (err) {
      console.error(err);
    }
  },
  async getSubjectTests ({ state, commit }, subjectId) {
    await this.$axios
      .get(`/v1/subject/${subjectId}/tests`)
      .then((res) => {
        commit("SET_SUBJECT_TESTS", res.data);
      })
      .catch((err) => console.error(err));
  },
  async getSubjectTestById ({ state, commit }, testId) {
    await this.$axios
      .get(`/v1/test/${testId}`)
      .then((res) => {
        commit("SET_SUBJECT_TEST", res.data);
      })
      .catch((err) => console.error(err));
  },
  async registerVisitCard ({ state, commit }, { cardId, hasNewContent }) {
    await this.$axios
      .post(`/v1/users/me/cards/${cardId}/visit`)
      .then((res) => {
        // only if the card has new content and user visits it we update the new content status
        // if (hasNewContent) {
        commit("UPDATE_NEW_CONTENT_STATUS_CARD", cardId);
        commit("UPDATE_NEW_CONTENT_STATUS_TOPIC", cardId);
        // }
      })
      .catch((err) => console.error(err));
  },
  completeCard ({ state, commit }, payload) {
    try {
      if (!payload.userHasLearned) {
        this.$axios.post(`/v1/users/me/cards/${payload.cardId}/learn`);
      } else {
        this.$axios.delete(`/v1/users/me/cards/${payload.cardId}/learn`);
      }
      setTimeout(() => {
        commit("UPDATE_COMPLETED_CARDS", payload);
      }, 350);
    } catch (err) {
      console.error(err);
    }
  },
  async getCard ({ state, commit }, cardId) {
    try {
      const { data } = await this.$axios.get(`/v1/card/${cardId}`);
      commit("SET_CURRENT_CARD", data);
    } catch (err) {
      console.error(err);
    }
  },
  async saveExamCheckScores (
    { state, commit },
    { testId, subjectId, requestBody }
  ) {
    await this.$axios
      .post(`/v1/test/${testId}`, requestBody)
      .then((res) => {
        commit("SET_SUBJECT_TEST_RESULT", res.data);
        commit("UPDATE_CURRENT_SUBJECT", {
          testId,
          updatedTestAttempt: res.data,
        });
        commit(
          "auth/UPDATE_USER_SUBJECTS_TEST_ATTEMPT",
          { subjectId, updatedTestAttempt: res.data },
          { root: true }
        );
      })
      .catch((err) => console.error(err));
  },
  async getLatestAttemptById ({ state, commit }, testId) {
    await this.$axios
      .get(`/v1/test/${testId}/latest-attempt`)
      .then((res) => commit("SET_SUBJECT_TEST_ANSWERS", res.data.answers))
      .catch((err) => console.error(err));
  },
};
