<template>
  <transition
    :css="false"
    @enter="enter"
    @leave="leave"
  >
    <div
      v-show="menuOpen"
      class="mobile-navigation"
    >
      <ul v-if="mainNavigation.fields">
        <li>
          <nuxt-link
            to="/"
            class="nav-link main-nav-link"
            @click.native="trackEventMain( { fields: { title: 'Home' } })"
          >
            Home
          </nuxt-link>
        </li>
        <li
          v-for="item in mainNavigation.fields.navigationItems"
          :key="item.sys.id"
        >
          <button
            v-if="item.fields.childItems"
            class="nav-link main-nav-link"
            :class="{'submenu-open': activeSubMenu === item.sys.id}"
            @click="handleSubmenuClick(item.sys.id)"
          >
            {{ item.fields.title }}
            <q-icon
              v-if="item.fields.childItems"
              name="chevron-down"
            />
          </button>
          <div v-else>
            <nuxt-link
              v-if="isRelativeUrl(item.fields.link)"
              :to="item.fields.link"
              class="nav-link main-nav-link"
              @click.native="trackEventMain(item)"
            >
              {{ item.fields.title }}
            </nuxt-link>
            <a
              v-else
              :href="item.fields.link"
              target="_blank"
              class="nav-link main-nav-link"
            >
              {{ item.fields.title }}
            </a>
          </div>
          <ul
            v-show="item.fields.childItems && activeSubMenu === item.sys.id"
            class="sub-nav"
          >
            <li
              v-for="child in item.fields.childItems"
              :key="child.sys.id"
            >
              <nuxt-link
                v-if="isRelativeUrl(child.fields.link)"
                :to="child.fields.link"
                class="nav-link sub-nav-link"
                @click.native="trackEventSub(item, child)"
              >
                {{ child.fields.title }}
              </nuxt-link>
              <a
                v-else
                :href="item.fields.link"
                target="_blank"
                class="nav-link sub-nav-link"
              >
                {{ child.fields.title }}
              </a>
            </li>
          </ul>
        </li>
        <li>
          <nuxt-link
            to="/mijnexamenbundel"
            class="nav-link main-nav-link"
            @click.native="trackEventLogin()"
          >
            Mijn Examenbundel
          </nuxt-link>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>

  import { mapState } from 'vuex'
  import { gsap } from 'gsap'
  import isRelativeUrl from '@/mixins/isRelativeUrl'
  import { gtmSendEvent } from '@/utils/gtm-send-event'

  export default {
    mixins: [isRelativeUrl],
    data () {
      return {
        scrollPosition: 0,
        activeSubMenu: null
      }
    },
    computed: {
      ...mapState({
        menuOpen: state => state.menuOpen,
        mainNavigation: state => state.mainNavigation
      })
    },
    watch: {
      menuOpen (isOpen) {
        if (isOpen && typeof window !== 'undefined') {
          this.scrollPosition = window.pageYOffset
          const $body = document.querySelector('body')
          const bodyPaddingRight = `${window.innerWidth - document.body.clientWidth}px`
          $body.style.paddingRight = bodyPaddingRight
          $body.style.overflow = 'hidden'
          $body.style.position = 'fixed'
          $body.style.top = `-${this.scrollPosition}px`
          $body.style.width = '100%'
        }
        if (!isOpen && typeof window !== 'undefined') {
          const $body = document.querySelector('body')
          $body.style.removeProperty('padding-right')
          $body.style.removeProperty('overflow')
          $body.style.removeProperty('position')
          $body.style.removeProperty('top')
          $body.style.removeProperty('width')
          window.scrollTo(0, this.scrollPosition)
        }
      }
    },
    methods: {
      handleSubmenuClick (id) {
        this.activeSubMenu = this.activeSubMenu === id ? null : id
      },
      enter (el, done) {
        const tl = gsap.timeline({ onComplete: done })
        tl.from(el, {
          y: '-100%',
          duration: 0.25,
          ease: 'expo.out'
        })
      },
      leave (el, done) {
        const tl = gsap.timeline({ onComplete: done })
        tl.to(el, {
          y: '-100%',
          duration: 0.2,
          ease: 'expo.in',
          clearProps: 'all'
        })
      },
      trackEventLogin () {
        gtmSendEvent('header-mobile', 'mijn-examenbundel', 'clicked')
      },
      trackEventMain (item) {
        gtmSendEvent('navigation-mobile', 'click-main-item', item.fields.title)
      },
      trackEventSub (parent, child) {
        gtmSendEvent('navigation-mobile', 'click-sub-item', parent.fields.title + ' / ' + child.fields.title)
      }
    }
  }
</script>

<style lang="scss">
.mobile-navigation {
  @apply fixed left-0 top-0 w-full h-full z-30 bg-white overflow-y-auto;
  padding-top: 6.125rem;

  li {
    @apply bg-white;
  }

  li:not(:last-child) {
    .nav-link {
      @apply border-b border-gray-200;
    }
  }

  .nav-link {
    @apply relative flex w-full items-center outline-none font-bold text-black py-8 px-6;

    &.nuxt-link-exact-active {
      @apply text-blue-500;
    }

    .q-icon {
      transition: transform 0.25s ease(easeOutSine);
    }

    &.submenu-open {
      .q-icon {
        transform: rotate(180deg);
      }
    }
  }

  .sub-nav-link {
    @apply pl-12;
  }
}
</style>
