import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _273ec46d = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _1fef8530 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _75333ae4 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _b223a272 = () => interopDefault(import('../pages/checkout/confirmation.vue' /* webpackChunkName: "pages/checkout/confirmation" */))
const _5ba90262 = () => interopDefault(import('../pages/article/index.vue' /* webpackChunkName: "pages/article/index" */))
const _2fe57d6e = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _8cca1438 = () => interopDefault(import('../pages/articles.vue' /* webpackChunkName: "pages/articles" */))
const _25a88167 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _749d2c3c = () => interopDefault(import('../pages/articles/category/index.vue' /* webpackChunkName: "pages/articles/category/index" */))
const _3a17cbe6 = () => interopDefault(import('../pages/articles/page/index.vue' /* webpackChunkName: "pages/articles/page/index" */))
const _72e68af4 = () => interopDefault(import('../pages/articles/category/_slug.vue' /* webpackChunkName: "pages/articles/category/_slug" */))
const _6cd7eb12 = () => interopDefault(import('../pages/articles/category/_slug/index.vue' /* webpackChunkName: "pages/articles/category/_slug/index" */))
const _61234eb6 = () => interopDefault(import('../pages/articles/category/_slug/page/_id.vue' /* webpackChunkName: "pages/articles/category/_slug/page/_id" */))
const _40eb3bb5 = () => interopDefault(import('../pages/articles/page/_id.vue' /* webpackChunkName: "pages/articles/page/_id" */))
const _e4635482 = () => interopDefault(import('../pages/mijnexamenbundel.vue' /* webpackChunkName: "pages/mijnexamenbundel" */))
const _788d5102 = () => interopDefault(import('../pages/mijnexamenbundel/index.vue' /* webpackChunkName: "pages/mijnexamenbundel/index" */))
const _a6037246 = () => interopDefault(import('../pages/mijnexamenbundel/account.vue' /* webpackChunkName: "pages/mijnexamenbundel/account" */))
const _293f73a0 = () => interopDefault(import('../pages/mijnexamenbundel/account/index.vue' /* webpackChunkName: "pages/mijnexamenbundel/account/index" */))
const _1b0c676c = () => interopDefault(import('../pages/mijnexamenbundel/account/mijn-vakken-boeken.vue' /* webpackChunkName: "pages/mijnexamenbundel/account/mijn-vakken-boeken" */))
const _5eadc912 = () => interopDefault(import('../pages/mijnexamenbundel/account/profile.vue' /* webpackChunkName: "pages/mijnexamenbundel/account/profile" */))
const _4234f419 = () => interopDefault(import('../pages/mijnexamenbundel/login.vue' /* webpackChunkName: "pages/mijnexamenbundel/login" */))
const _4750d8ca = () => interopDefault(import('../pages/mijnexamenbundel/onboarding.vue' /* webpackChunkName: "pages/mijnexamenbundel/onboarding" */))
const _4e4fb9de = () => interopDefault(import('../pages/mijnexamenbundel/onboarding/index.vue' /* webpackChunkName: "pages/mijnexamenbundel/onboarding/index" */))
const _a41749d8 = () => interopDefault(import('../pages/mijnexamenbundel/onboarding/step-1.vue' /* webpackChunkName: "pages/mijnexamenbundel/onboarding/step-1" */))
const _a3a68dd0 = () => interopDefault(import('../pages/mijnexamenbundel/onboarding/step-5.vue' /* webpackChunkName: "pages/mijnexamenbundel/onboarding/step-5" */))
const _a3fb1ad6 = () => interopDefault(import('../pages/mijnexamenbundel/onboarding/step-2.vue' /* webpackChunkName: "pages/mijnexamenbundel/onboarding/step-2" */))
const _a3deebd4 = () => interopDefault(import('../pages/mijnexamenbundel/onboarding/step-3.vue' /* webpackChunkName: "pages/mijnexamenbundel/onboarding/step-3" */))
const _c4d2afce = () => interopDefault(import('../pages/mijnexamenbundel/onboarding/step-3/index.vue' /* webpackChunkName: "pages/mijnexamenbundel/onboarding/step-3/index" */))
const _7442d68a = () => interopDefault(import('../pages/mijnexamenbundel/onboarding/step-3/learning-path.vue' /* webpackChunkName: "pages/mijnexamenbundel/onboarding/step-3/learning-path" */))
const _0f6d7d1a = () => interopDefault(import('../pages/mijnexamenbundel/onboarding/step-3/profiles.vue' /* webpackChunkName: "pages/mijnexamenbundel/onboarding/step-3/profiles" */))
const _a3c2bcd2 = () => interopDefault(import('../pages/mijnexamenbundel/onboarding/step-4.vue' /* webpackChunkName: "pages/mijnexamenbundel/onboarding/step-4" */))
const _41dfabea = () => interopDefault(import('../pages/mijnexamenbundel/_id.vue' /* webpackChunkName: "pages/mijnexamenbundel/_id" */))
const _54bb79a5 = () => interopDefault(import('../pages/mijnexamenbundel/_id/_slug.vue' /* webpackChunkName: "pages/mijnexamenbundel/_id/_slug" */))
const _caa51f30 = () => interopDefault(import('../pages/mijnexamenbundel/_id/_slug/index.vue' /* webpackChunkName: "pages/mijnexamenbundel/_id/_slug/index" */))
const _4ba8f9be = () => interopDefault(import('../subjectModal/subjectModalId.vue' /* webpackChunkName: "subjectModal/subjectModalId" */))
const _0a8a26c3 = () => interopDefault(import('../pages/mijnexamenbundel/_id/_slug/leren-en-oefenen.vue' /* webpackChunkName: "pages/mijnexamenbundel/_id/_slug/leren-en-oefenen" */))
const _3cae376e = () => interopDefault(import('../pages/mijnexamenbundel/_id/_slug/maak-de-balans-op.vue' /* webpackChunkName: "pages/mijnexamenbundel/_id/_slug/maak-de-balans-op" */))
const _4687fb29 = () => interopDefault(import('../pages/mijnexamenbundel/_id/_slug/ook-handig.vue' /* webpackChunkName: "pages/mijnexamenbundel/_id/_slug/ook-handig" */))
const _04d99b14 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _3f394cb1 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _7377fbce = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _4da9f5d3 = () => interopDefault(import('../pages/quickscan.vue' /* webpackChunkName: "pages/quickscan" */))
const _f218d672 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _37d5738f = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _4a7b3370 = () => interopDefault(import('../pages/products/bundles/index.vue' /* webpackChunkName: "pages/products/bundles/index" */))
const _3ea5d34a = () => interopDefault(import('../pages/products/bundles/_id/index.vue' /* webpackChunkName: "pages/products/bundles/_id/index" */))
const _421315da = () => interopDefault(import('../pages/products/bundles/_id/_slug.vue' /* webpackChunkName: "pages/products/bundles/_id/_slug" */))
const _59f2611a = () => interopDefault(import('../pages/article/_slug.vue' /* webpackChunkName: "pages/article/_slug" */))
const _33327fb9 = () => interopDefault(import('../pages/products/_id/index.vue' /* webpackChunkName: "pages/products/_id/index" */))
const _317bde71 = () => interopDefault(import('../pages/products/_id/_slug.vue' /* webpackChunkName: "pages/products/_id/_slug" */))
const _1d4b0d3b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1b946bf3 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))
const _bca21f94 = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _668790b4 = () => interopDefault(import('../pages/_slug/_childSlug.vue' /* webpackChunkName: "pages/_slug/_childSlug" */))
const _276c7f92 = () => interopDefault(import('../pages/_slug/_childSlug/index.vue' /* webpackChunkName: "pages/_slug/_childSlug/index" */))
const _70a8fae2 = () => interopDefault(import('../pages/_slug/_childSlug/_grandchildSlug/index.vue' /* webpackChunkName: "pages/_slug/_childSlug/_grandchildSlug/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/afrekenen",
    component: _273ec46d,
    children: [{
      path: "",
      component: _1fef8530,
      name: "checkout___nl"
    }, {
      path: "/afrekenen/betalen",
      component: _75333ae4,
      name: "checkout-payment___nl"
    }, {
      path: "/afrekenen/bevestiging",
      component: _b223a272,
      name: "checkout-confirmation___nl"
    }]
  }, {
    path: "/artikel",
    component: _5ba90262,
    name: "article___nl"
  }, {
    path: "/contact",
    component: _2fe57d6e,
    name: "contact___nl"
  }, {
    path: "/examentips",
    component: _8cca1438,
    children: [{
      path: "",
      component: _25a88167,
      name: "articles___nl"
    }, {
      path: "categorie",
      component: _749d2c3c,
      name: "articles-category___nl"
    }, {
      path: "page",
      component: _3a17cbe6,
      name: "articles-page___nl"
    }, {
      path: "categorie/:slug",
      component: _72e68af4,
      children: [{
        path: "",
        component: _6cd7eb12,
        name: "articles-category-slug___nl"
      }, {
        path: "page/:id?",
        component: _61234eb6,
        name: "articles-category-slug-page-id___nl"
      }]
    }, {
      path: "page/:id",
      component: _40eb3bb5,
      name: "articles-page-id___nl"
    }]
  }, {
    path: "/mijnexamenbundel",
    component: _e4635482,
    children: [{
      path: "",
      component: _788d5102,
      name: "mijnexamenbundel___nl"
    }, {
      path: "account",
      component: _a6037246,
      children: [{
        path: "",
        component: _293f73a0,
        name: "mijnexamenbundel-account___nl"
      }, {
        path: "mijn-vakken-boeken",
        component: _1b0c676c,
        name: "mijnexamenbundel-account-mijn-vakken-boeken___nl"
      }, {
        path: "profile",
        component: _5eadc912,
        name: "mijnexamenbundel-account-profile___nl"
      }]
    }, {
      path: "login",
      component: _4234f419,
      name: "mijnexamenbundel-login___nl"
    }, {
      path: "onboarding",
      component: _4750d8ca,
      children: [{
        path: "",
        component: _4e4fb9de,
        name: "mijnexamenbundel-onboarding___nl"
      }, {
        path: "stap-1",
        component: _a41749d8,
        name: "mijnexamenbundel-onboarding-step-1___nl"
      }, {
        path: "stap-1b",
        component: _a3a68dd0,
        name: "mijnexamenbundel-onboarding-step-5___nl"
      }, {
        path: "stap-2",
        component: _a3fb1ad6,
        name: "mijnexamenbundel-onboarding-step-2___nl"
      }, {
        path: "stap-3",
        component: _a3deebd4,
        children: [{
          path: "",
          component: _c4d2afce,
          name: "mijnexamenbundel-onboarding-step-3___nl"
        }, {
          path: "leerweg",
          component: _7442d68a,
          name: "mijnexamenbundel-onboarding-step-3-learning-path___nl"
        }, {
          path: "profielen",
          component: _0f6d7d1a,
          name: "mijnexamenbundel-onboarding-step-3-profiles___nl"
        }]
      }, {
        path: "stap-4",
        component: _a3c2bcd2,
        name: "mijnexamenbundel-onboarding-step-4___nl"
      }]
    }, {
      path: ":id",
      component: _41dfabea,
      name: "mijnexamenbundel-id___nl",
      children: [{
        path: ":slug?",
        component: _54bb79a5,
        children: [{
          path: "",
          component: _caa51f30,
          name: "mijnexamenbundel-id-slug___nl",
          children: [{
            path: "kaart/:subjectModalId",
            component: _4ba8f9be,
            name: "mijnexamenbundel-id-slug-subject-modal___nl"
          }]
        }, {
          path: "leren-en-oefenen",
          component: _0a8a26c3,
          name: "mijnexamenbundel-id-slug-leren-en-oefenen___nl",
          children: [{
            path: "kaart/:subjectModalId",
            component: _4ba8f9be,
            name: "mijnexamenbundel-id-slug-leren-en-oefenen-subject-modal___nl"
          }]
        }, {
          path: "maak-de-balans-op",
          component: _3cae376e,
          name: "mijnexamenbundel-id-slug-maak-de-balans-op___nl",
          children: [{
            path: "kaart/:subjectModalId",
            component: _4ba8f9be,
            name: "mijnexamenbundel-id-slug-maak-de-balans-op-subject-modal___nl"
          }]
        }, {
          path: "ook-handig",
          component: _4687fb29,
          name: "mijnexamenbundel-id-slug-ook-handig___nl",
          children: [{
            path: "kaart/:subjectModalId",
            component: _4ba8f9be,
            name: "mijnexamenbundel-id-slug-ook-handig-subject-modal___nl"
          }]
        }]
      }]
    }]
  }, {
    path: "/over-examenbundel",
    component: _04d99b14,
    name: "about___nl"
  }, {
    path: "/privacy",
    component: _3f394cb1,
    name: "privacy___nl"
  }, {
    path: "/producten",
    component: _7377fbce,
    name: "products___nl"
  }, {
    path: "/quickscan",
    component: _4da9f5d3,
    name: "quickscan___nl"
  }, {
    path: "/winkelwagen",
    component: _f218d672,
    name: "cart___nl"
  }, {
    path: "/zoeken",
    component: _37d5738f,
    name: "search___nl"
  }, {
    path: "/producten/voordeelpakketten",
    component: _4a7b3370,
    name: "products-bundles___nl"
  }, {
    path: "/producten/voordeelpakketten/:id",
    component: _3ea5d34a,
    name: "products-bundles-id___nl"
  }, {
    path: "/producten/voordeelpakketten/:id/:slug",
    component: _421315da,
    name: "products-bundles-id-slug___nl"
  }, {
    path: "/artikel/:slug",
    component: _59f2611a,
    name: "article-slug___nl"
  }, {
    path: "/producten/:id",
    component: _33327fb9,
    name: "products-id___nl"
  }, {
    path: "/producten/:id/:slug",
    component: _317bde71,
    name: "products-id-slug___nl"
  }, {
    path: "/",
    component: _1d4b0d3b,
    name: "index___nl"
  }, {
    path: "/:slug",
    component: _1b946bf3,
    children: [{
      path: "",
      component: _bca21f94,
      name: "slug___nl"
    }, {
      path: ":childSlug",
      component: _668790b4,
      children: [{
        path: "",
        component: _276c7f92,
        name: "slug-childSlug___nl"
      }, {
        path: ":grandchildSlug",
        component: _70a8fae2,
        name: "slug-childSlug-grandchildSlug___nl"
      }]
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
