export const state = () => ({
  products: [],
  productsPerPage: 18,
  schoolSubjects: [],
  selectedSchoolSubjects: [],
  selectedProductTypes: []
})

export const mutations = {
  SET_PRODUCTS (state, data) {
    state.products = data
  },
  SET_SCHOOL_SUBJECTS (state, data) {
    state.schoolSubjects = data
  },
  SET_SELECTED_SCHOOL_SUBJECTS (state, subjects) {
    state.selectedSchoolSubjects = subjects
  },
  SET_SELECTED_PRODUCT_TYPES (state, data) {
    state.selectedProductTypes = data
  }
}

export const getters = {
  totalPages (state) {
    if (Object.keys(state.products).length === 0) return 0
    return Math.ceil(state.products.total / state.productsPerPage)
  },
  currentPage (state) {
    if (Object.keys(state.products).length === 0) return 0
    return Math.ceil(state.products.skip / state.productsPerPage) + 1
  },
  schoolSubjectsFields (state) {
    if (!state.schoolSubjects.length) return []
    return state.schoolSubjects.map(subject => subject.fields)
  },
  schoolSubjectsOptions (state) {
    if (!state.selectedSchoolSubjects.length) return null
    return {
      'fields.schoolSubject.sys.contentType.sys.id': 'schoolSubject',
      'fields.schoolSubject.fields.title[in]': state.selectedSchoolSubjects.join(',')
    }
  },
  productTypeOptions (state) {
    if (!state.selectedProductTypes.length) return null
    return { 'fields.productType[in]': state.selectedProductTypes.join(',') }
  },
  productsWithBanners (state, getters, rootState) {
    const banners = rootState.config.fields.productsArchiveCTAs
    if (!banners || banners.length === 0 || !state.products.items) return state.products.items

    const bannersWithCta = banners.map((banner) => {
      return {
        ...banner.fields,
        contentType: 'CallToAction'
      }
    })

    const products = Array.from(state.products.items)
    const bannerPositions = [6, 16]

    bannersWithCta.forEach((banner, i) => {
      if (bannerPositions[i] > products.length) {
        products.push(banner)
      } else {
        products.splice(bannerPositions[i], 0, banner)
      }
    })

    return products
  }
}

export const actions = {
  async get ({ state, commit, getters, rootGetters }, { mutate = true, page = 1 }) {
    const skipNum = (page - 1) * state.productsPerPage
    const products = await this.$contentful.getEntries({
      content_type: 'product',
      limit: state.productsPerPage,
      skip: skipNum,
      ...rootGetters['levels/levelOptions'],
      ...getters.schoolSubjectsOptions,
      ...getters.productTypeOptions
    })
    if (mutate) {
      const productsItems = products.items.map(item => item.fields)
      const getSubjectTitle = (subject) => {
        if (!subject || !subject.fields) return ''
        return subject.fields.title
      }
      // manually sort products before commiting to avoid content flash
      const sortedProducts = Array.from(productsItems)
      sortedProducts.sort((a, b) => {
        return getSubjectTitle(a.schoolSubject).localeCompare(getSubjectTitle(b.schoolSubject))
      })
      products.items = sortedProducts
      commit('SET_PRODUCTS', products)
    }
    return products
  },
  async getSchoolSubjects ({ commit }) {
    const { items } = await this.$contentful.getEntries({
      content_type: 'schoolSubject',
      order: 'fields.title'
    })
    commit('SET_SCHOOL_SUBJECTS', items)
  }
}
